import {withRouter} from 'react-router-dom';
import AbstractByAircraft from './AbstractByAircraft';

class ConfigDeployerByAircraft extends AbstractByAircraft {

    constructor(props) {
        super(props);
        this.specificUri = 'auto_remote_deployers';
        this.state = {
            resList: [],
            loading: true,
            currentPageNumber: 1,
            orderBy: 'ASC',
            currentFieldOrder: null,
            navigation: {
                totalItems: 0,
                first: '',
                next: '',
                last: '',
                totalPage: 0
            },
            error: {
                status: false,
                message: ''
            },
            anchor: '#config_deployer',
            fieldsName: [
                'Reference (hash)',
                'Branch',
                'Applied At'
            ],
            tableTitle: this.props.tableTitle,
            showBoxSolid: false,
            boxColor: '',
            boxIcon: '',
            boxMessageTitle: '',
            boxMessageContent: '',
        }
    }

    updateMessageHeader = (query) => {
        this.Auth
            .fetch(this.specificUri + query)
            .then(res => {
                let totalItems = res['hydra:totalItems'] ? res['hydra:totalItems'] : 0;
                if (totalItems === 0) {
                    this.setState({
                        showBoxSolid: true,
                        boxColor: 'notice',
                        boxIcon: 'info',
                        boxMessageTitle: 'No configuration has been deployed yet',
                        boxMessageContent: 'Configuration will be deployed automatically when the connectivity should be available.',
                    })
                }
            })
            .catch(e => {
                console.log("error e", e.toString());
            });
    }

    componentDidMount() {
        super.componentDidMount();
        this.updateMessageHeader('?transportUnit.uuid=' + this.props.transportUnitUuid);
    }

    getProperty = (field) => {
        let property;

        switch (field) {
            case 'Reference (hash)':
                property = 'autoRemoteDeployerHash.commitHash';
                break;
            case 'Branch':
                property = 'autoRemoteDeployerHash.branch';
                break;
            case 'Applied At':
                property = 'appliedAt';
                break;
            default:
                property = '';
        }

        return property;
    }

    fieldClicked = (fields) => {
        if (this.props.clickable) {
            const configDeployerUuid = fields[0];
            this.props.history.push(`/${this.props.icao}/config_deployers/${configDeployerUuid}`);
        }
        return true;
    }

    getFields = () => {
        let fields = [];
        const resList = [...this.state.resList];

        resList.forEach(res => {
            let hash = res.autoRemoteDeployerHash ? res.autoRemoteDeployerHash.commitHash.substring(0, 8) : ''

            fields.push([
                res.uuid,
                hash,
                res.autoRemoteDeployerHash.branch,
                res.appliedAt,
            ])
        });

        return fields;
    };
}

export default withRouter(ConfigDeployerByAircraft);
