import React, {Component} from 'react'
import AircraftSummary from './AircraftSummary';
import Button from '../../../components/UI/Button/Button';
import Aux from '../../../hoc/Aux/Aux';
import AircraftFlightDetail from './AircraftFlightDetail'
import {grantPermission} from "../../../hoc/RoleService/RoleService";
import BannerAircraftIsTest from './BannerAircraftIsTest';
import AircraftSummaryLegacy from "./AircraftSummaryLegacy";
import AircraftSummarySync from "./AircraftSummarySync";

class aircraftSummaryList extends Component {
    state = {
        zoom: 1
    };

    render() {
        let aircraftSummaryElement = <></>

        aircraftSummaryElement = AircraftSummaryLegacy(this.props.transportUnit.lastFullMediaPartNumber, this.props.transportUnit.lastOtaMediaPartNumber, this.props.transportUnit.lastOtaLog)
        if (this.props.transportUnit.synchronizationType === "sync_v2_legacy" || this.props.transportUnit.synchronizationType === "sync_v2_download") {
            aircraftSummaryElement = AircraftSummarySync(this.props.transportUnit.mediaSyncPartNumbers)
        }

        return (
            <Aux>
                <div className="row">
                    <div className="col-md-12">
                        <div className="box box-body">
                            <div className="box-body box-profile">
                                <div className="row">
                                    <div className="col-md-2">
                                        <p className="text-center"><i className="fa fa-plane fa-4x"/></p>
                                        <h3 className="profile-username text-center"> {this.props.transportUnit.registration}</h3>
                                        <p className="text-muted text-center">{this.props.transportUnit.transportUnitModel ? this.props.transportUnit.transportUnitModel.manufacturerCompany.name + ' ' + this.props.transportUnit.transportUnitModel.name : ''}</p>
                                    </div>
                                    <div className="col-md-4 col-md-offset-2">
                                        <AircraftSummary name='Operator Company'
                                                         value={this.props.transportUnit.operatorCompany ? this.props.transportUnit.operatorCompany.icao + " - " + this.props.transportUnit.operatorCompany.name : ''}/>

                                        <AircraftSummary name='Configured Company'
                                                         value={this.props.transportUnit.configuredCompany ? this.props.transportUnit.configuredCompany.icao + " - " + this.props.transportUnit.configuredCompany.name : ''}/>

                                        <AircraftSummary name='Activated At'
                                                         value={this.props.transportUnit.activatedAt ? this.props.transportUnit.activatedAt : 'Not activated yet'}/>
                                        {this.props.transportUnit.deactivatedAt ?
                                            <AircraftSummary name='Deactivated At'
                                                             value={this.props.transportUnit.deactivatedAt}/> : ""}
                                        {this.props.transportUnit.numberOfSeats ?
                                            <AircraftSummary name='Number of Seats'
                                                             value={this.props.transportUnit.numberOfSeats}/> : ""}
                                    </div>
                                    <div className="col-md-2 col-md-offset-2">
                                        {grantPermission('aircraft_erp_button', this.props.auth) ?
                                            <Button
                                                clicked={this.props.erpClicked}
                                                icon='fa fa-database'
                                                status='btn'
                                                buttonClass='btn btn-block btn-primary'
                                                content='Display ERP'/> : ""}
                                        {grantPermission('aircraft_flightradar_button', this.props.auth) ?
                                            <Button
                                                clicked={this.props.flightRadarClicked}
                                                icon='fa fa-compass fa-1x'
                                                status='btn'
                                                buttonClass='btn btn-block btn-primary'
                                                content='FlightRadar'/> : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <BannerAircraftIsTest
                    isTest={this.props.transportUnit.isTest}
                />

                <div className="row">
                    <div className="col-md-4">
                        <div className="box box-default">
                            <div className="box-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className={"info-box bg-" + this.props.status.background_color}>
                                            <span className="info-box-icon"><i
                                                className={'glyphicon glyphicon-' + this.props.status.icon}/></span>
                                            <div className="info-box-content">
                                                <span className="info-box-text">System Status</span>
                                                <span className="info-box-number"> {this.props.status.message}</span>
                                                <div className="progress">
                                                    <div className="progress-bar" style={{
                                                        width: '100%',
                                                        background: this.props.status.color_text_black ? "black" : "white"
                                                    }}>
                                                    </div>
                                                </div>
                                                <div
                                                    className="progress-description">{this.props.status.explanation}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <tbody>
                                        {aircraftSummaryElement}
                                        <tr>
                                            <td><b><i title="Logs" className="fa fa-area-chart"
                                                      style={{fontSize: 17, color: '#3c8dbc'}}/> Last Logs Received </b>
                                            </td>
                                            <td className="pull-right">{this.props.transportUnit.lastLogReceived || '-'}</td>
                                        </tr>
                                        <tr>
                                            <td><b><i title="HeartBeat" className="fa fa-heartbeat"
                                                      style={{fontSize: 17, color: '#3c8dbc'}}/> Last Heartbeat </b>
                                            </td>
                                            <td className="pull-right">{this.props.transportUnit.lastHeartbeat || '-'}</td>
                                        </tr>
                                        {grantPermission('rmc', this.props.auth) ?
                                            <tr>
                                                <td><b><i title="HeartBeat" className="fa fa-signal"
                                                          style={{fontSize: 17, color: '#3c8dbc'}}/> Last RMC Event </b>
                                                </td>
                                                <td className="pull-right">{this.props.transportUnit.lastRemoteConnexionReceivedAt || '-'}</td>
                                            </tr> : ""}
                                        {grantPermission('configuration_deployer', this.props.auth) &&
                                        this.props.transportUnit.remoteDeployerBranch ?
                                            <tr>
                                                <td>
                                                    <b><i title="Config deployed" className="fa fa-cogs"
                                                          style={{fontSize: 17, color: '#3c8dbc'}}/> Last config
                                                        deployed </b>
                                                </td>
                                                <td className="pull-right">
                                                    {this.props.transportUnit.lastRemoteDeployerDate && this.props.transportUnit.lastRemoteDeployerHash
                                                        ? this.props.transportUnit.lastRemoteDeployerDate + ' (' +
                                                        this.props.transportUnit.lastRemoteDeployerHash.substring(0, 8) + ')' : '-'}
                                                </td>
                                            </tr> : ''}
                                        <tr>
                                            <td>
                                                <b><i title="Config deployed" className="fa fa-cogs"
                                                      style={{fontSize: 17, color: '#3c8dbc'}}/> Initial configuration
                                                </b>
                                            </td>
                                            <td className="pull-right">
                                                {this.props.transportUnit.autoconfigurationReferenceInitial}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <b><i title="Config deployed" className="fa fa-cogs"
                                                      style={{fontSize: 17, color: '#3c8dbc'}}/> Current deployed configuration
                                                </b>
                                            </td>
                                            <td className="pull-right">
                                                {this.props.transportUnit.lastAutoConfigDate && this.props.transportUnit.lastAutoConfigRef
                                                    ? this.props.transportUnit.lastAutoConfigDate + ' (' +
                                                    this.props.transportUnit.lastAutoConfigRef + ')' : '-'}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <ul className="list-group list-group-unbordered">

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="box box-default">
                            <div className="box-body">
                                <div className="row">
                                    {this.props.transportUnit.areFlightTracked ?
                                        <AircraftFlightDetail transportUnit={this.props.transportUnit}
                                                              auth={this.props.auth} icao={this.props.icao}/> :
                                        <div className="col-md-12">Flights are not tracked for this aircraft.</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Aux>
        )
    }
}

export default aircraftSummaryList;
