import React from "react";
import Popup from "reactjs-popup";
import './Style/card.css';

const CardFlight = ({ data }) => (
    <div className="left">
        <div>
            Altitude In Meter :  <span className='text-info'>{data.flight.lastDataPositions.altitudeInMeters.toFixed()} m</span>
        </div> 
        <div>
            Speed km/h :  <span className='text-info'>{data.flight.lastDataPositions.speedKilometersPerHour.toFixed()} km/h ({data.flight.lastDataPositions.speedKnots} kts)</span>
        </div>
        <div className="phases">
            Phases :  <span className="text-info">{data.phases}</span>
        </div>
    </div>
);

class ToolTipPositions extends React.Component {
 
    render() {
        let phases = [];
        if(!!this.props.transportUnit.lastDataFlights.historyPhases){ 
            phases = this.props.transportUnit.lastDataFlights.historyPhases;
        }

        let listItems = phases.map((phase,i) => {
            let arrow = "";
            if(0 < i){
                arrow = (<i className="fa fa-arrow-right"></i>)
            }
            if(this.props.transportUnit.lastDataFlights.phase !== phase){
                return (
                    <span key={i}>
                        <span>{arrow}</span>
                        <span className="label label-default" >
                        {phase}
                         </span>
                    </span>
                );
            }else{
                return (
                    <span key={i}>
                        <span>{arrow}</span>
                        <span className="label label-success" >
                        {phase}
                         </span>
                 </span>
                );
            }

        });

        let data = {
            flight: this.props.flight,
            phases: listItems,
            transportUnit: this.props.transportUnit
        }
         return (
            <div style={{float: 'center'}}>

                <Popup
                    trigger={<span className="button"><i className="fa fa-info-circle btn-info btn-xs"></i></span>}
                    position="right center"
                    on="hover"
                >
                    <CardFlight data={data} />
                </Popup>
            </div>
        )
    }
}

export default ToolTipPositions;
