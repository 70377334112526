import React from 'react';
import {withRouter} from 'react-router-dom';

import AbstractByAircraft from './AbstractByAircraft';
import './AlertByAircraft.js'
import ToolTipPositions from '../../UI/ToolTipPosition/ToolTipPositions';
import './css/Flights.css';

const divStyleCity = {
    fontWeight: 'bold'
};


class FlightsAircraft extends AbstractByAircraft {
    constructor(props) {
        super(props);
        this.specificUri = 'flights';
        this.state = {
            resList: [],
            loading: true,
            currentPageNumber: 1,
            orderBy: 'DESC',
            currentFieldOrder: null,
            navigation: {
                totalItems: 0,
                first: '',
                next: '',
                last: '',
                totalPage: 0
            },
            status: {
                color: 'success',
                icon: 'Closed'
            },
            error: {
                status: false,
                message: ''
            },
            anchor: '#flights',
            fieldsName: [
                'Flight Number',
                'From',
                'To',
                'Departure At',
                'Current position',
                'Arrived At',
                'Flight Duration',
                'Status',
                'System Status'
            ],
            popup: '',
            dataTransportUnit: this.props.dataTransportUnit
        }

    }

    fieldClicked = (fields) => {
        if (this.props.clickable) {
            const flightuuid = fields[0];
            this.props.history.push(`/${this.props.icao}/flights/${flightuuid}`)
        }
        return true
    }

    getProperty = (field) => {
        let property;

        switch (field) {
            case 'Flight Number':
                property = 'flightNumber';
                break;
            case 'From':
                property = 'fromAirport.city';
                break;
            case 'To':
                property = 'toAirport.city';
                break;
            case 'Flight Duration':
                property = 'flightDurationInMinutes';
                break;
            case 'Arrived At':
                property = 'arrivalAt';
                break;
            case 'Departure At':
                property = 'departureAt';
                break;
            case 'System Status':
                property = 'transportUnitStatus';
                break;
            default:
                property = '';
        }

        return property;
    }

    getFields = () => {

        let fields = [];
        let transportUnit = this.state.dataTransportUnit;
        if (transportUnit
            && (transportUnit['hydra:description'] === 'Not Found'
            || transportUnit['hydra:description'] === 'Access Denied.')) {
            return fields;
        }
        const resList = [...this.state.resList];

        let phase;

        if (!!transportUnit.lastDataFlights && null !== transportUnit.lastDataFlights.phase) {
            phase = transportUnit.lastDataFlights.phase;
        }


        resList.forEach(res => {
            if (null !== res.flightNumber) {
                let flightPositionsValue, statusPosition, phaseName, phaseNameClass;

                if (phase && null !== phase) {
                    phaseNameClass = phase;
                    phaseName = phase.slice(0, 1).toUpperCase() + phase.slice(1, phase.length);
                }

                let formatedTime;

                formatedTime = this.formatTime(res.flightDurationInMinutes);

                if (null === res.flightDurationInMinutes && !!res.lastDataPositions) {
                    statusPosition = (<span>
                            <span style={divStyleCity}>
                                In progress
                             </span>
                    </span>)

                    flightPositionsValue = (<table className='table table-bordered table-hover table-striped dataTable'>
                        <thead>
                        <tr role='row'>
                            <th>Phase</th>
                            <th>Altitude</th>
                            <th>Speed</th>
                            <th>Heading</th>
                            <th>More infos</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className="center"> {phaseName} <i className={phaseNameClass}></i></td>
                            <td className="center"> {res.lastDataPositions.altitudeInFeet.toFixed()} ft</td>
                            <td className="center"> {res.lastDataPositions.speedKnots} kts</td>
                            <td className="center"> {res.lastDataPositions.heading}°</td>
                            <td className="center"><ToolTipPositions flight={res}
                                                                     transportUnit={this.state.dataTransportUnit}/></td>
                        </tr>
                        </tbody>
                    </table>)
                }

                if (res.flightDurationInMinutes >= 0 && !!res.arrivalAt) {
                    statusPosition = (<span>
                            <span style={divStyleCity}>
                                Landed
                             </span>
                    </span>)

                    flightPositionsValue = (
                        <div className="center">--</div>
                    )
                }

                let f_airport = (<div className="center">-</div>);

                if (null != res.fromAirport) {
                    f_airport = (
                        <span>
                        <span style={divStyleCity}>
                            {res.fromAirport.city}
                        </span>
                        <small> ({res.fromAirport.icao})</small>
                    </span>
                    )
                }

                let toCity = (<div className="center">-</div>);

                if (null != res.toAirport) {
                    toCity = (
                        <span>
                        <span style={divStyleCity}>
                            {res.toAirport.city}
                        </span>
                        <small> ({res.toAirport.icao})</small>
                    </span>
                    )
                }

                let statusColor = this.props.statusHandler.getFullStatus(res.transportUnitStatus).color;

                fields.push([
                    res.uuid,
                    res.flightNumber,
                    (<div>
                        {f_airport}
                    </div>),
                    (<div>
                        {toCity}
                    </div>),
                    res.departureAt,
                    <div>
                        {flightPositionsValue}
                    </div>,
                    res.arrivalAt,
                    formatedTime,
                    <div>
                        {statusPosition}
                    </div>,
                    <span
                        className={"label label-" + statusColor}>{res.transportUnitStatus ? res.transportUnitStatus : "Unknown"}</span>
                ])
            }
        });


        return fields;
    }
}

export default withRouter(FlightsAircraft);
