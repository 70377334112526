import React from 'react';
import {withRouter} from 'react-router-dom';

import AbstractByAircraft from './AbstractByAircraft';

class UsbMediaSyncLog extends AbstractByAircraft {
    constructor(props) {
        super(props);
        this.specificUri = 'usb_media_sync_logs';
        this.state = {
            resList: [],
            loading: true,
            currentPageNumber: 1,
            orderBy: 'ASC',
            currentFieldOrder: null,
            navigation: {
                totalItems: 0,
                first: '',
                next: '',
                last: '',
                totalPage: 0
            },
            error: {
                status: false,
                message: ''
            },
            anchor: '#usbsync',
            fieldsName: [
                'Date HeartBeat',
                'New P/N'
            ]
        };
         
    }
 
    getProperty = (field) => {
        let property;

        switch (field) {
            case 'Date HeartBeat':
                property = 'dateHeartBeat';
                break;
            case 'MediaPartNumber':
                property = 'mediaPartNumber';
                break;
            default:
                property = '';
        }

        return property;
    }
 
    getFields = () => {
        let fields = [];
        let aircraftHeartBeatArray = [];

        const resList = [...this.state.resList];

        resList.forEach(res => {  
            aircraftHeartBeatArray.push([
                res.aircraftHeartBeat.uuid
            ])

            let linkDateHeartBeat;
            if(res.aircraftHeartBeat){
                linkDateHeartBeat = (
                <span><a href={"/" + this.props.icao + "/heartbeat/" +res.aircraftHeartBeat.uuid}>{res.dateHeartBeat} </a></span>
            )
  }

            fields.push([
                res.uuid,  
                linkDateHeartBeat,
                res.mediaPartNumber 
            ])
        });
   
        return fields;
    };
}

export default withRouter(UsbMediaSyncLog);
