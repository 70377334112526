import React, { Component } from 'react'

class AutoConfigStatus extends Component {

    render() {
        let status = <span className='label label-default'>{this.props.status}</span>
        if (this.props.status === 'running') {
            status = <span className='label label-info'>{this.props.status}</span>
        } else if (this.props.status === 'success') {
            status = <span className='label label-success'>{this.props.status}</span>
        } else if (this.props.status === 'failed') {
            status = <span className='label label-danger'>{this.props.status}</span>
        } else if (this.props.status === 'canceled') {
            status = <span className='label label-warning'>{this.props.status}</span>
        } else if (this.props.status === 'skipped') {
            status = <span className='label label-warning'>{this.props.status}</span>
        } else if (this.props.status === 'manual') {
            status = <span className='label label-notice'>{this.props.status}</span>
        } else if (this.props.status === 'scheduled') {
            status = <span className='label label-notice'>{this.props.status}</span>
        } else if (this.props.status === 'created') {
            status = <span className='label label-notice'>{this.props.status}</span>
        } else if (this.props.status === 'pending') {
            status = <span className='label label-notice'>{this.props.status}</span>
        }
        
        return (
            <>
                {status}
            </>
        )
    }
}
export default AutoConfigStatus;
