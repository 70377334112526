import React from "react";
import Aux from '../../../hoc/Aux/Aux';

const BoxSolid = props => {
    const {color='info', icon='info', title='', content=''} = props;

    return (
        <Aux>
            <div className="row">
                <div className="col-md-12">
                    <div className={"box box-" + color  + " box-solid"}>
                        <div className={"callout callout-" + color}>
                            <h4 className="box-title"> <i className={"fa fa-" + icon} /> {title} </h4>
                            <p>{content}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Aux>
    );
}

export default BoxSolid;
