import React from 'react';

import Aux from '../../../hoc/Aux/Aux';


const aircraftSummary = (props) => (
    <Aux>
        <b>{props.name}</b> <span className="pull-right">{props.value}</span> <br/>
    </Aux>

);

export default aircraftSummary;
