import React, { Component } from 'react'
import Aux from '../../../hoc/Aux/Aux';

class bannerAircraftIsTest extends Component {
    render() {
        if (this.props.isTest === true) {
            return (
                <Aux>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="box box-notice box-solid">
                                    <div className="callout callout-notice">
                                        <h4><i className="fa fa-info" /> Notice</h4>
                                        Fake aircraft - This is a server created for testing purposes only, typically installed at Display or customer offices.
                                    </div>
                            </div>
                        </div>
                    </div>
                </Aux>
            )
        } else {
            return "";
        }
    }
}

export default bannerAircraftIsTest;
