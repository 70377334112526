import React from 'react';
import { NavLink } from 'react-router-dom';

const footer = () => {
        let icao = window.location.pathname.split("/")[1];
        return (
            <footer className="main-footer">
                    <div className="pull-right hidden-xs">

                    </div>
                    <strong>Copyright &copy; <a href='#0'>Display Interactive</a>.</strong> All rights
                    reserved.
                    <NavLink exact to={"/" + icao + "/privacy-policy"}> Privacy and Cookies policy.</NavLink>
                    <NavLink exact to={"/" + icao + "/legal-notice"}> Legal Notice.</NavLink>
            </footer>
        );
}


export default footer;
