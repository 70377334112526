import React from 'react'

const selectInput = (props) => {
    return (
        <div className="col-xs-6 col-sm-6 col-md-3 col-mg-2" style={{marginTop: 5}}>
            <div className="has-feedback">
                <select id={props.id}
                        style={{fontSize: 13, height:40} }
                        value={props.selectTerm}
                        onChange={event => props.onSelectChange(event)}
                        className="form-control">
                    {props.default ? <option value='default'>{props.default}</option> : null}
                    {props.options.map(option =>
                        <option key={option.status} value={option.status}>
                            {option.displaying}
                        </option>)}
                </select>
            </div>
        </div>
    )
}

export default selectInput
