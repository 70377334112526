import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class PartialPagination extends Component {

    render() {
        const { currentPage, location, navigationClicked } = this.props;
        const query = new URLSearchParams(location.search);

        let newQueryWithoutPagination = '';
        for (let param of query.entries()) {
            if (param[0] === 'page') {
                continue;
            }

            if (param[1]) {
                newQueryWithoutPagination += '&' + param[0] + '=' + param[1];
            } else {
                newQueryWithoutPagination += '&' + param[0];
            }
            newQueryWithoutPagination += this.props.hash
        }

        let previousPage = (
            <li id='previous'
                className='previous'
                onClick={(currentPage === 1) ? null : () => navigationClicked(currentPage - 1)}>
                <Link to={'?page=' + (currentPage - 1) + newQueryWithoutPagination} aria-label="Previous">Previous</Link>
            </li>
        );

        let firstPage = (
            <li id='first'
                className='first'
                onClick={() => navigationClicked(1)}>
                <Link to={'?page=' + (1) + newQueryWithoutPagination} aria-label="First">First</Link>
            </li>
        );

        let nextPage = (
            <li
                id='next'
                className='next'
                onClick={() => navigationClicked(currentPage + 1)}>
                <Link to={'?page=' + (currentPage + 1) + newQueryWithoutPagination}>Next</Link>
            </li>
        );

        let actualPage = (
            <li id='current' className='current disabled'>
                <a href='#0' aria-label="current">{currentPage}</a>
            </li>
        );

        if (currentPage === 1) {
            previousPage = (
                <li id='previous' className='previous disabled'>
                    <a href='#0' style={{ color: 'lightgrey' }} aria-label="Previous">Previous</a>
                </li>
            );
            firstPage = (
                <li id='first' className='first disabled'>
                    <a href='#0' style={{ color: 'lightgrey' }} aria-label="First">First</a>
                </li>
            );
        }

        if (this.props.numberOfResult < 10) {
            nextPage = (
                <li id='previous' className='next disabled'>
                    <a href='#0' style={{ color: 'lightgrey' }} aria-label="Next">Next</a>
                </li>
            );
        }

        return (
            <ul className='pagination'>
                {previousPage}
                {firstPage}
                {actualPage}
                {nextPage}
            </ul>
        )
    }
}

export default PartialPagination;

PartialPagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    navigation: PropTypes.shape({
        totalPage: PropTypes.number
    }),
    location: PropTypes.object,
    navigationClicked: PropTypes.func
};
