import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import './Login.css';
import AuthService from '../../hoc/AuthService/AuthService';
import Secured from '../Sso/Secured';

class Login extends Component {
    constructor(props){
        super(props);
        this.Auth = new AuthService();
        this.state = {
            credentials: {
                username: '',
                password: ''
            },
            isError: false,
            rememberMe: false,
            currentKey: '',
            prevPath: ''
        };
    }

    componentDidMount() {
        document.body.classList.value = 'hold-transition login-page';

        if ((localStorage.getItem("rememberMe") && localStorage.getItem("credentials"))) {
            const credentials = JSON.parse(localStorage.getItem("credentials"));
            this.setState({
                rememberMe: true,
                credentials: credentials
            });
        }
    }

    componentWillUpdate(nextProps, nextState) {
        if ((this.state.rememberMe !== nextState.rememberMe) && nextState.rememberMe === false) {
            this.removeRememberMe();
        }
    }

    removeRememberMe = () => {
        localStorage.removeItem("credentials");
        localStorage.removeItem("rememberMe");
    };

    updateRememberMe = () => {
        if (this.state.rememberMe) {
            localStorage.setItem("credentials", JSON.stringify(this.state.credentials));
            localStorage.setItem("rememberMe", 1);
        }
    };

    handleEmailChange = (event) => {
        const credentials = {...this.state.credentials};
        credentials.username = event.target.value;
        this.setState({credentials: credentials})
    };

    handlePasswordChange = (event) => {
        const credentials = {...this.state.credentials};
        credentials.password = event.target.value;
        this.setState({credentials: credentials})
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.closedErrorMessageHandler();

        this.updateRememberMe();

        this.Auth.login(this.state.credentials.username, this.state.credentials.password)
            .then(res => {
                if (res.code === 401) {
                    this.props.history.push('/login');
                }
                let destination = '/'

                if (typeof this.props.location.state !== 'undefined') {
                    destination = this.props.location.state.previousUrl
                }
                this.props.history.push(destination)
            }).catch(e => {
            this.setState({isError: true});
        })
    };

    closedErrorMessageHandler = () => {
        this.setState({isError: false});
    };

    handleRememberMe = () => {
    const rememberMe = !this.state.rememberMe;
        this.setState({rememberMe: rememberMe});
    };


    render () {
        return (
            <div className="login-box">
                <Secured auth={this.Auth} logoutsso={false} />
           </div>

        )
    }
}

export default withRouter(Login);
