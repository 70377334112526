import {grantPermission} from "../RoleService/RoleService";

export default class CompanyService {

    constructor() {
        this.icao = process.env.REACT_APP_DISPLAY_CODE_URL ? process.env.REACT_APP_DISPLAY_CODE_URL : 'DI';
    }

    get displayIcao() {
        return this.icao.toUpperCase();
    }

    isCompanyExist = (companyIcao, companyList) => {
        return companyList.some(aCompany => aCompany.icao === companyIcao.toUpperCase())
    }

    isDisplayIcao = (icao) => {
        return icao === this.icao.toLowerCase();
    }

    isDisplayUser = (auth) => {
        return grantPermission('company_display', auth) || grantPermission('admin', auth)
    }

    redirectUserIfNotDisplayUser = (icao, auth) => {
        if (this.isDisplayIcao(icao) && !this.isDisplayUser(auth)) {
            window.location.replace('/403');
        }
    }
}
