import React from "react";
import "./settings.css"
import moment from 'moment';

const settingsDetail = (props) => {

    let content = "All monitored parameters are disabled in ERP for this Transport Unit";
    const fieldHeartBeat = <i style={{fontSize: 12, color: '#3c8dbc'}} title="HeartBeat" className="fa fa-heartbeat"/>;
    const fieldLogs = <i style={{fontSize: 12, color: '#3c8dbc'}} title="Logs" className="fa fa-area-chart"/>;
    const fieldsOtaSync = <i style={{fontSize: 13, color: '#3c8dbc'}} title="Ota Sync"
                             className="fa fa-cloud-download"/>;

    let ota_message = "";
    let logs_message1 = "";
    let logs_message2 = "";
    let logs_message3 = "";
    let logs_message4 = "";
    let heartbeat_message = "";

    let ota_notice_delay_in_hours = props.transportUnit.otaMaxDelayInHours || props.transportUnit.defaultOtaLogMaxDelay;
    let ota_warning_delay_in_hours = ota_notice_delay_in_hours * 2;
    let ota_notice_date = new Date();
    ota_notice_date.setHours(ota_notice_date.getHours() - ota_notice_delay_in_hours);
    ota_notice_date = moment(ota_notice_date).format('YYYY-MM-DD h:mm:ss');
    let ota_warning_date = new Date();
    ota_warning_date.setHours(ota_warning_date.getHours() - ota_warning_delay_in_hours);
    ota_warning_date = moment(ota_warning_date).format('YYYY-MM-DD h:mm:ss');

    let logs_notice_delay_in_day = props.transportUnit.maxUnprocessedStatsDelayInDays || props.transportUnit.defaultUgoLogUnprocessedMaxDelay;
    let logs_warning_delay_in_day = logs_notice_delay_in_day * 2;
    let logs_notice_date = new Date();
    logs_notice_date.setDate(logs_notice_date.getDate() - logs_notice_delay_in_day);
    logs_notice_date = moment(logs_notice_date).format('YYYY-MM-DD h:mm:ss');
    let logs_warning_date = new Date();
    logs_warning_date.setDate(logs_warning_date.getDate() - logs_warning_delay_in_day);
    logs_warning_date = moment(logs_warning_date).format('YYYY-MM-DD h:mm:ss');

    let average_logs = props.transportUnit.minStatsFilesPerDay || props.transportUnit.defaultUgoLogFilesNumberPerDay;
    let max_stats_pear_day = props.transportUnit.statsMaxDelayInDays || props.transportUnit.defaultUgoLogStatMaxDelayInDay;
    let min_logs_date = new Date();
    let max_logs_date = new Date();
    min_logs_date.setDate(min_logs_date.getDate() - logs_notice_delay_in_day - max_stats_pear_day);
    min_logs_date = moment(min_logs_date).format('YYYY-MM-DD h:mm:ss');
    max_logs_date.setDate(max_logs_date.getDate() - logs_notice_delay_in_day);
    max_logs_date = moment(max_logs_date).format('YYYY-MM-DD h:mm:ss');

    let logs_notice_delay_in_day_piwik = props.transportUnit.maxUnprocessedStatsPiwikDelayInDays || props.transportUnit.defaultPiwikUnprocessedMaxDelay;
    let logs_warning_delay_in_day_piwik = logs_notice_delay_in_day * 2;
    let logs_notice_date_piwik = new Date();
    logs_notice_date_piwik.setDate(logs_notice_date_piwik.getDate() - logs_notice_delay_in_day_piwik);
    logs_notice_date_piwik = moment(logs_notice_date_piwik).format('YYYY-MM-DD h:mm:ss');
    let logs_warning_date_piwik = new Date();
    logs_warning_date_piwik.setDate(logs_warning_date_piwik.getDate() - logs_warning_delay_in_day_piwik);
    logs_warning_date_piwik = moment(logs_warning_date_piwik).format('YYYY-MM-DD h:mm:ss');

    let average_logs_piwik = props.transportUnit.minStatsPiwikFilesPerDay|| props.transportUnit.defaultPiwikFilesNumberPerDay;
    let max_stats_pear_day_piwik = props.transportUnit.statsPiwikMaxDelayInDays || props.transportUnit.defaultPiwikStatMaxDelayInDay;
    let min_logs_date_piwik = new Date();
    let max_logs_date_piwik = new Date();
    min_logs_date_piwik.setDate(min_logs_date_piwik.getDate() - logs_notice_delay_in_day_piwik  - max_stats_pear_day_piwik );
    min_logs_date_piwik = moment(min_logs_date_piwik).format('YYYY-MM-DD h:mm:ss');
    max_logs_date_piwik.setDate(max_logs_date_piwik.getDate() - logs_notice_delay_in_day_piwik );
    max_logs_date_piwik = moment(max_logs_date_piwik).format('YYYY-MM-DD h:mm:ss');

    let heartbeat_notice_delay_in_hours = props.transportUnit.heartBeatMaxDelayInHours || props.transportUnit.defaultHeartBeatMaxDelay;
    let heartbeat_warning_delay_in_hours = heartbeat_notice_delay_in_hours * 2;
    let heartbeat_notice_date = new Date();
    heartbeat_notice_date.setHours(heartbeat_notice_date.getHours() - heartbeat_notice_delay_in_hours);
    heartbeat_notice_date = moment(heartbeat_notice_date).format('YYYY-MM-DD h:mm:ss');
    let heartbeat_warning_date = new Date();
    heartbeat_warning_date.setDate(heartbeat_warning_date.getDate() - heartbeat_warning_delay_in_hours);
    heartbeat_warning_date = moment(heartbeat_warning_date).format('YYYY-MM-DD h:mm:ss');

    if (props.transportUnit.isOtaSyncActivated) {
        ota_message = (
            <tr>
                <td>OTA Contents Sync</td>
                <td>{fieldsOtaSync}</td>
                <td>Allowed delay without Media OTA performed</td>
                <td><span
                    className="label label-notice">More than {ota_notice_delay_in_hours} hours ({ota_notice_date})</span>
                </td>
                <td><span
                    className="label label-warning">More than {ota_warning_delay_in_hours} hours ({ota_warning_date})</span>
                </td>
            </tr>
        )
    }

    if (props.transportUnit.isLogsSent) {
        logs_message1 = (
            <tr>
                <td className="col">Logs (3.0+)</td>
                <td>{fieldLogs}</td>
                <td>Allowed delay without processed log on ground</td>
                <td><span
                    className="label label-notice"> More than {logs_notice_delay_in_day} days ({logs_notice_date})</span>
                </td>
                <td><span
                    className="label label-warning"> More than {logs_warning_delay_in_day} days ({logs_warning_date})</span>
                </td>
            </tr>
        );
        logs_message2 = (
            <tr>
                <td className="col">Logs (3.0+)</td>
                <td>{fieldLogs}</td>
                <td>Average of UGO log files
                    per day expected between <span className="label label-info">{min_logs_date}</span> and <span
                        className="label label-info">{max_logs_date}</span> <span
                        className="label label-info">({max_stats_pear_day} days)</span></td>
                <td><span className="label label-notice">Less than {average_logs} files per day</span></td>
                <td><span className="label label-warning">Less than {average_logs / 2} files per day</span></td>
            </tr>
        );
    }

    if (props.transportUnit.isPiwikStatsSent) {
        logs_message3 = (
            <tr>
                <td className="col">Logs (2.x)</td>
                <td>{fieldLogs}</td>
                <td>Allowed delay without processed log on ground</td>
                <td><span
                    className="label label-notice"> More than {logs_notice_delay_in_day_piwik} days ({logs_notice_date_piwik})</span>
                </td>
                <td><span
                    className="label label-warning"> More than {logs_warning_delay_in_day_piwik} days ({logs_warning_date_piwik})</span>
                </td>
            </tr>
        );
        logs_message4 = (
            <tr>
                <td className="col">Logs (2.x)</td>
                <td>{fieldLogs}</td>
                <td>Average of Piwik log files
                    per day expected between <span className="label label-info">{min_logs_date_piwik}</span> and <span
                        className="label label-info">{max_logs_date_piwik}</span> <span
                        className="label label-info">({max_stats_pear_day_piwik} days)</span></td>
                <td><span className="label label-notice">Less than {average_logs_piwik} files per day</span></td>
                <td><span className="label label-warning">Less than {average_logs_piwik / 2} files per day</span></td>
            </tr>
        );
    }

    if (props.transportUnit.isHeartBeatSent) {
        heartbeat_message = (
            <tr>
                <td>Heartbeat</td>
                <td>{fieldHeartBeat}</td>
                <td>Allowed delay without heartbeat</td>
                <td><span
                    className="label label-notice"> More than {heartbeat_notice_delay_in_hours} hours ({heartbeat_notice_date})</span>
                </td>
                <td><span
                    className="label label-warning"> More than {heartbeat_warning_delay_in_hours} hours ({heartbeat_warning_date})</span>
                </td>
            </tr>
        );
    }

    if (props.transportUnit.isHeartBeatSent || props.transportUnit.isLogsSent || props.transportUnit.isOtaSyncActivated) {
        content = (
            <div className="table-responsive">
                <table className="table table-striped">
                    <thead className="textTable">
                    <tr>
                        <th>#</th>
                        <th>Type</th>
                        <th>Name</th>
                        <th>Notice</th>
                        <th>Warning</th>
                    </tr>
                    </thead>
                    <tbody>
                    {ota_message}
                    {logs_message1}
                    {logs_message2}
                    {logs_message3}
                    {logs_message4}
                    {heartbeat_message}
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <div className="settings">
            {content}
        </div>
    )
}

export default settingsDetail
