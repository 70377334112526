import React from 'react';

import PartialPagination from "../Pagination/PartialPagination";
import Pagination from "../Pagination/Pagination";
import Aux from '../../../hoc/Aux/Aux';

const tableFooter = (props) => {

    const currentStart = ((props.currentPage-1)*10)+1;
    let pageTo = (currentStart-1) + 10;

    if (props.navigation.partial) {
        return (
            <Aux>
                {partialFooter(props, currentStart, pageTo)}
            </Aux>
        );
    }

    if (pageTo > props.navigation.totalItems) {
        pageTo = props.navigation.totalItems;
    }

    let pagination = null;

    if (props.navigation.totalPage > 1) {
        pagination = (
            <Pagination {...props}/>
        );
    }
    const tableFooter = props.numberOfResult > 0 ? (
        <Aux>
            <div className='col-sm-5' style={{marginTop: '30px'}}>
                Showing {currentStart} to {pageTo} of {props.navigation.totalItems} entries
            </div>
            <div className='col-sm-7 text-right'>
                {pagination}
            </div>
        </Aux>
    ) : ""

    return (
        <Aux>
            {tableFooter}
        </Aux>
    );

};

function partialFooter(props, currentStart, pageTo) {

    let message = "Showing " + currentStart + " to " + pageTo + " entries."

    if (props.numberOfResult === 0) {
        message = "No results found."
    }


    let pagination = (
        <PartialPagination {...props}/>
    );

    return (
        <Aux>
            <div className='col-sm-5' style={{marginTop: '30px'}}>
                {message}
            </div>
            <div className='col-sm-7 text-right'>
                {pagination}
            </div>
        </Aux>
    );
}

export default tableFooter;
