import React from 'react'

const modal = (props) => {
    return (
        <div className={'modal'} id={'modal-'+props.color}  style={{display: 'block'}}>
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className={'modal-header alert-'+props.color}>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span onClick={props.closeDetailHandler} aria-hidden="true">×</span>
                        </button>
                        <h4 className="modal-title" id="exampleModalLabel">{props.title}</h4>
                    </div>
                    <div className="modal-body">
                        <div className={"row"}>
                            <div className={"col-md-12"}>
                            {props.children}
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-default pull-left" onClick={props.closeDetailHandler} data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default modal
