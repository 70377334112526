import React from 'react'; 


import SidebarItems from './SidebarItems/SidebarItems';

const sidebar = ({auth}) => (
    <section className='main-sidebar'>
        <SidebarItems auth={auth}/>
    </section>
);

export default sidebar;
