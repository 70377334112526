import React, { Component } from 'react';
import Keycloak from 'keycloak-js';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Spinner from '../../components/UI/Spinner/Spinner'
import Aux from '../../hoc/Aux/Aux';
import { grantPermission } from "../../hoc/RoleService/RoleService";

class Secured extends Component {
    constructor(props) {
        super(props);
        this.auth = this.props.auth;
        this.state = { keycloak: null, authenticated: false };
        this.baseUrlAuthSso = process.env.REACT_APP_API_SSO_AUTH;
        this.baseUrlClient = process.env.REACT_APP_CLIENT_HOST;
        this.baseTokenUser = process.env.REACT_APP_CLIENT_TOKEN;
        this.state = { logoutsso: props.logoutsso, readytorefresh: false };
    }

    componentDidMount() {
        this.initLogin()
    }

    initLogin = () => {
        const keycloak = Keycloak('/keycloak.json');

        keycloak.init({ onLoad: 'login-required' })
            .then(authenticated => {
                this.setState({ keycloak: keycloak, authenticated: authenticated },
                    () => {
                        if (true === this.state.logoutsso) {
                            this.logout();
                            return;
                        }
                        if (keycloak.idToken) {
                            this.auth.setToken(keycloak.token);
                            this.authSso();
                        }
                    });
            });
    }

    authSso = () => {
        const url = this.baseUrlAuthSso;
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this.auth.getToken()
            }
        };
        axios.get(url, config)
            .then(res => {
                //const user = this.state.keycloak.tokenParsed
                this.redirectToDashboardIsAccess();
            })
    }

    redirectToDashboardIsAccess = () => {
        const nextPath = localStorage.getItem('prevUrl');

        if (nextPath && nextPath !== '/' && nextPath !== '/logout') {
            window.location.href = nextPath
            return true
        }

        if (grantPermission('dashboard', this.auth)) {
            window.location.href = "/"
            return true
        }

        if (grantPermission('air_map', this.auth)) {
            window.location.href = "/airmap"
            return true
        }

        if (grantPermission('aircraft_list', this.auth)) {
            window.location.href = "/aircraft"
            return true
        }

        if (grantPermission('slideshow', this.auth)) {
            window.location.href = "/slideshow"
            return true
        }

        if (grantPermission('rmc', this.auth)) {
            window.location.href = "/remote_connection"
            return true
        }

        window.location.href = "/"
    };

    logout = () => {
        this.setState({
            logoutsso: false,
        }, () => {
            this.auth.removeToken();
            this.state.keycloak.logout({ redirectUri: this.baseUrlClient + '/login' });
        })
    }

    render() {
        if (this.state.keycloak) {
            if (this.state.authenticated) {
                return (
                    <Aux>
                        <div className="box-body">
                            <Spinner />
                        </div>
                    </Aux>
                )
            } else {
                return (<div>Unable to authenticate!</div>)
            }
        }
        return (
            <div><b>Authorization check in progress ...</b></div>
        );
    }
}

export default withRouter(Secured);
