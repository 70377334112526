import {withRouter} from 'react-router-dom';

import AbstractByAircraft from './AbstractByAircraft';

class OtaLogsByAircraft extends AbstractByAircraft {
    constructor(props) {
        super(props);
        this.specificUri = 'ota_logs';
        this.state = {
            resList: [],
            loading: true,
            currentPageNumber: 1,
            orderBy: 'ASC',
            currentFieldOrder: null,
            navigation: {
                totalItems: 0,
                first: '',
                next: '',
                last: '',
                totalPage: 0
            },
            anchor: '#sync',
            error: {
                status: false,
                message: ''
            },
            fieldsName: [
                'Path Prefix',
                'Started At',
                'Finished At',
                'Airport',
                'Status',
                'Error Type',
                'Error Message'
            ]
        };
    }

    getProperty = (field) => {
        let property;

        switch (field) {
            case 'Path Prefix':
                property = 'pathPrefix';
                break;
            case 'Started At':
                property = 'startedAt';
                break;
            case 'Finished At':
                property = 'finishedAt';
                break;
            case 'Airport':
                property = 'airport.name';
                break;
            case 'Status':
                property = 'status';
                break;
            case 'Error Type':
                property = 'errorType';
                break;
            case 'Error Message':
                property = 'errorMessage';
                break;
            default:
                property = '';
        }

        return property;
    };

    getFields = () => {
        let fields = [];
        const resList = [...this.state.resList];

        resList.forEach(res => {
            fields.push([
                res.uuid,
                res.pathPrefix,
                res.startedAt,
                res.finishedAt,
                res.airport ? res.airport.name : '-',
                res.status,
                res.errorType,
                res.errorMessage,
            ])
        });

        return fields;
    };
}

export default withRouter(OtaLogsByAircraft);
