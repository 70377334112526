import React, {Suspense, useEffect} from 'react';
import {NavLink, Route, Switch, useHistory} from 'react-router-dom';
import Layout from './hoc/Layout/Layout';
import Login from './components/Login/Login';
import Auth from './hoc/AuthService/AuthService';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import LegalNotice from './components/LegalNotice/LegalNotice';
import StatusHandler from "./utils/StatusHandler";
import AircraftDetail from "./containers/Aircrafts/AircraftDetail/AircraftDetail";
import {grantPermission, RoleService} from "./hoc/RoleService/RoleService";
import CookieConsent from 'react-cookie-consent';
import UrlChanger from "./containers/Dashboard/UrlChanger";
import Flights from "./containers/Flights/Flights";

const Secured = React.lazy(() => import('./components/Sso/Secured'));
const Dashboard = React.lazy(() => import('./containers/Dashboard/Dashboard'));
const Aircraft = React.lazy(() => import('./containers/Aircrafts/Aircrafts'));
const Page404 = React.lazy(() => import('./components/Page404/Page404'));
const Page403 = React.lazy(() => import('./components/Page403/Page403'));
const HeartBeat = React.lazy(() => import('./components/HeartBeat/HeartBeat'));
const MediaSync = React.lazy(() => import('./components/MediaSync/MediaSync'));
const KnowledgeBaseDocument = React.lazy(() => import('./components/KnowledgeBaseDocument/KnowledgeBaseDocument'));
const MediaSyncCampaign = React.lazy(() => import('./components/MediaSync/MediaSyncCampaign'));
const UgoLogArchive = React.lazy(() => import('./components/UgoLogArchive/UgoLogArchive'));
const Flight = React.lazy(() => import('./components/Flight/Flight'));
const AirMap = React.lazy(() => import('./containers/Map/AirMap'));
const SlideShow = React.lazy(() => import('./containers/SlideShow/SlideShow'));
const RemoteMaintenance = React.lazy(() => import('./components/RemoteMaintenance/RemoteMaintenance'));
const RemoteConnexion = React.lazy(() => import('./components/RemoteConnexion/RemoteConnexion'));

const App = props => {
    const auth = new Auth();
    const statusHandler = new StatusHandler();
    const history = useHistory();

    let icao = window.location.pathname.split("/")[1];

    const handleKeyPress = (e) => {
        if (e.key === "ArrowLeft") {
            reduceSidebar()
        }
        if (e.key === "ArrowRight") {
            extendSidebar();
        }
    }
    const reduceSidebar = () => {
        document.body.classList.add('sidebar-collapse');
        document.body.classList.remove('sidebar-open');
    }

    const extendSidebar = () => {
        document.body.classList.remove('sidebar-collapse');
        document.body.classList.add('sidebar-open');
    }

    const sidebarToggleHandler = () => {
        document.addEventListener("keydown", handleKeyPress);
    };

    useEffect(() => {
        sidebarToggleHandler();
    });

    if (auth.getToken()) {
        if (!grantPermission('dashboard', auth) ) {

            let currentPathList = window.location.pathname.split("/");
            let pathListToRedirect = ['aircraft', 'airmap', 'slideshow'];

            if (!pathListToRedirect.some(r => currentPathList.includes(r))) {
                auth.fetch('companies').then(
                    res => {
                        let companyList = res['hydra:member']
                        if (companyList.length < 1) {
                            history.push("/403");
                        }

                        icao = companyList.filter(company => company.isShowInDashboard === true)[0].icao.toLowerCase()

                        if (grantPermission('aircraft_list', auth)) {
                            window.location.replace("/"+icao+"/aircraft");

                        } else if (grantPermission('air_map', auth)) {
                            window.location.replace("/"+icao+"/airmap");

                        } else if (grantPermission('slideshow', auth)) {
                            window.location.replace("/"+icao+"/slideshow");
                        }
                    }
                )
            }
        }
    }

    return (
        <Layout sidebarToggleClicked={sidebarToggleHandler} auth={auth}>
            <CookieConsent
            location="bottom"
            cookieName="displayCookieConsentForNoc"
            expires={365}
              style={{
                    background: "linear-gradient(to right, #d3d3d3 , #ecf0f5, #d3d3d3)",
                    color: "#333",
                    fontWeight: "bolder",
                }}
                buttonStyle={{
                    background: "#bf9976",
                    color: "#fff",
                    fontWeight: "bolder",
                    borderRadius: "3px",
                }}
            >
                This website uses mandatory cookies. More details available inside our
                <NavLink exact to={"/" + icao +"/privacy-policy"}><span className="center"> Privacy and Cookies policy</span></NavLink>
            </CookieConsent>
            <Suspense fallback={<p>Loading...</p>}>
                <Switch>
                    <Route path='/login' component={Login} exact />
                    <Route path='/logout' render={() => (<Secured auth={auth} logoutsso={true}/>)} exact/>
                    <Route path='/403' render={() => (<Page403 />)} />
                    <Route path='/' component={UrlChanger} exact />

                    <RoleService
                        exact path="/:icao"
                        component={Dashboard}
                        role={['dashboard']}
                        auth={auth}
                        statusHandler={statusHandler}
                    />
                    <RoleService
                        exact path="/:icao/aircraft/:uuid"
                        component={AircraftDetail}
                        role={['aircraft_show']}
                        auth={auth}
                        statusHandler={statusHandler}
                    />
                    <RoleService
                        exact path="/:icao/flights"
                        component={Flights}
                        role={['flight']}
                        auth={auth}
                        statusHandler={statusHandler}
                    />
                    <RoleService
                        exact path="/:icao/aircraft"
                        component={Aircraft}
                        role={['aircraft_list']}
                        auth={auth}
                        statusHandler={statusHandler}
                    />
                    <RoleService
                        exact path="/:icao/heartbeat/:uuid"
                        component={HeartBeat}
                        role={['heartbeat']}
                        auth={auth}
                        statusHandler={statusHandler}
                    />
                    <RoleService
                        exact path="/:icao/media_sync/:uuid"
                        component={MediaSync}
                        role={['media_sync']}
                        auth={auth}
                        statusHandler={statusHandler}
                    />
                    <RoleService
                        exact path="/:icao/knowledge_base_documents/:uuid"
                        component={KnowledgeBaseDocument}
                        role={['role_public_kb', 'role_private_kb']}
                        auth={auth}
                        statusHandler={statusHandler}
                    />
                    <RoleService
                        exact path="/:icao/media_sync/:uuid/campaign/:campaign_uuid"
                        component={MediaSyncCampaign}
                        role={['media_sync']}
                        auth={auth}
                        statusHandler={statusHandler}
                    />
                    <RoleService
                        exact path="/:icao/ugo-log-archive/:uuid"
                        component={UgoLogArchive}
                        role={['ugo_log']}
                        auth={auth}
                        statusHandler={statusHandler}
                    />
                    <RoleService
                        exact path="/:icao/remote-connection/:uuid"
                        component={RemoteConnexion}
                        role={['rmc']}
                        auth={auth}
                        statusHandler={statusHandler}
                    />
                    <RoleService
                        exact path="/:icao/flights/:uuid"
                        component={Flight}
                        role={['flight']}
                        auth={auth}
                        statusHandler={statusHandler}
                    />
                    <RoleService
                        exact path="/:icao/airmap"
                        component={AirMap}
                        role={['air_map']}
                        auth={auth}
                        statusHandler={statusHandler}
                    />
                    <RoleService
                        exact path="/:icao/slideshow"
                        component={SlideShow}
                        role={['slideshow']}
                        auth={auth}
                        statusHandler={statusHandler}
                    />
                    <RoleService
                        exact path="/:icao/remote_connection"
                        component={RemoteMaintenance}
                        role={['rmc']}
                        auth={auth}
                        statusHandler={statusHandler}
                    />
                    <Route path='/:icao/legal-notice' render={() => (<LegalNotice auth={auth} />)} exact />
                    <Route path='/:icao/privacy-policy' render={() => (<PrivacyPolicy auth={auth} />)} exact />
                    <Route path='/:icao/dashboard' render={() => (<Dashboard auth={auth} />)} exact />
                    <Route path='/*' render={() => (<Page404 />)} />
                </Switch>
            </Suspense>
        </Layout>
    );
}

export default App;
