import {withRouter} from 'react-router-dom';

import AbstractByAircraft from './AbstractByAircraft';
import React from "react";

class UgoLogArchiveByAircraft extends AbstractByAircraft {
    
    constructor(props) {
        super(props);
        this.specificUri = 'ugo_log_archives';
        this.state = {
            resList: [],
            loading: true,
            currentPageNumber: 1,
            orderBy: 'ASC',
            currentFieldOrder: null,
            navigation: {
                totalItems: 0,
                first: '',
                next: '',
                last: '',
                totalPage: 0
            },
            error: {
                status: false,
                message: ''
            },
            anchor: '#ugolog',
            fieldsName: [
                'Archive Name',
                'Archive Type',
                'Creation Archive At',
                'Received on AWS s3 At',
                'Has been Uncompressed',
            ]
        };
    }

    getProperty = (field) => {
        let property;

        switch (field) {
            case 'archiveName':
                property = 'archiveName';
                break;
            case 'archiveType':
                property = 'archiveType';
                break;
            case 'creationArchiveDatetime':
                property = 'creationArchiveDatetime';
                break;
            case 's3CreationDatetime':
                property = 's3CreationDatetime';
                break;
            case 'hasBeenUncompressed':
                property = 'hasBeenUncompressed';
                break;
            default:
                property = '';
        }

        return property;
    };

    fieldClicked = (fields) => {
        if (this.props.clickable) {
            const UgoLogArchiveUuid = fields[0]
            this.props.history.push(`/${this.props.icao}/ugo-log-archive/${UgoLogArchiveUuid}`)
        }
        return true
    }

    getFields = () => {
        let fields = [];
        const resList = [...this.state.resList];

        resList.forEach(res => {
            let color = res.hasBeenUncompressed ? "success" : "danger"
            fields.push([
                res.uuid,
                res.archiveName,
                res.archiveType,
                res.creationArchiveDatetime,
                res.s3CreationDatetime,
                <span className={"label label-" + color }>{res.hasBeenUncompressed ? "Yes" : "No" }</span>
            ])
        });

        return fields;
    };
}

export default withRouter(UgoLogArchiveByAircraft);
