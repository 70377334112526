import jwt_decode from "jwt-decode";

export default class AuthService {
    constructor() {
        this.baseUrl = process.env.REACT_APP_API_HOST;
    }

    login = (username, password) => {
        return this.fetch('login_check', {
            method: 'POST',
            body: JSON.stringify({
                username,
                password
            })
        }).then(res => {
            this.setToken(res.token);

            return res;
        })
    };

    loggedIn = () => {
        return this.getToken() && this.isValidToken();
    };

    isValidToken = () => {
        let decodedToken = jwt_decode(this.getToken());

        const dateObjExpire = decodedToken.exp * 1000;
        const dateObjCurrent = Date.now();

        if (dateObjCurrent > dateObjExpire) {
            this.removeToken();
            return false;
        }

        return true;
    }

    removeToken = () => {
        localStorage.removeItem('token');
    }

    setToken = (idToken) => {
        let decodedToken = jwt_decode(idToken);

        this.setRoleLocal(this.getRoles(decodedToken));

        localStorage.setItem('token', idToken);
    };

    getToken = () => {
        return localStorage.getItem('token');
    };

    logginIfTokenIsNull = (prevUrl = null) => {
        if (!this.loggedIn()) {
            localStorage.setItem('prevUrl', prevUrl)
            window.location = '/login';
        } else {
            localStorage.removeItem('prevUrl')
        }
    }

    fetch = (url, options) => {
        if (!this.loggedIn()) {
            window.location = '/login';
            return Promise.reject(new Error('Not logged, redirect to /login'));
        }

        const headers = {
            'Content-Type': 'application/json'
        };
        headers['Authorization'] = 'Bearer ' + this.getToken();

        return fetch(process.env.REACT_APP_API_HOST + url, {
            headers,
            ...options
        })
            .then(response => response.json())
    };

    parseJwt = () => {
        const token = this.getToken()
        const base64Url = token.split('.')[1]
        const base64 = base64Url.replace('-', '+').replace('_', '/')

        return JSON.parse(window.atob(base64))
    }

    getRoles = (decodedToken) => {
        if (decodedToken.resource_access.noc) {
            return decodedToken.resource_access.noc.roles
        }
        return [];
    }

    setRoleLocal = (roles) => {
        localStorage.setItem('userRoles', roles)
    }
}
