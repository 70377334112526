import React from 'react';
import {Route} from 'react-router-dom';
import Unauthorized from "../../components/Unauthorized/Unauthorized";
import history from '../../history';
import ListRole from "../../utils/ListRole";

/**
 * Returns true if user has at least one role of roles
 */
export const grantAnyPermissionOf = (roles, auth) => {
    return roles.some(role => grantPermission(role, auth));
}

export const grantPermission = (role, auth) => {
    if (auth) {
        let path = history.location.pathname;
        auth.logginIfTokenIsNull(path);
    }
    const listRole = new ListRole()
    const permittedRoles = localStorage.getItem('userRoles');

    if (permittedRoles == null && !compareRoleInToken(auth)) {
        return false
    }

    if (permittedRoles.includes(listRole.getRole("admin").role)) {
        return true
    }

    if (permittedRoles.includes(listRole.getRole(role).role)) {
        return true
    }

    return false
};

export const grantSpecialPermission = (requestedRoles, auth) => {

    if (auth) {
        let path = history.location.pathname;
        auth.logginIfTokenIsNull(path);
    }

    const permittedRoles = localStorage.getItem('userRoles');

    if (permittedRoles == null) {
        return false
    }

    if (permittedRoles.includes(requestedRoles[0])) {
        return true
    }

    return false
};

export function RoleService({ component: Component, role, auth, statusHandler, path }) {
    return (
        <>
            {grantAnyPermissionOf(role, auth) && (
                <Route exact path={path}
                    render={() => (
                        <Component auth={auth} statusHandler={statusHandler} />
                    )}
                />
            )}

            {!grantAnyPermissionOf(role, auth) && (
                <Route
                    render={() => (
                        <Unauthorized />
                    )}
                />
            )}
        </>
    );
}

export const compareRoleInToken = (auth) => {
    if (!auth) {
        window.location = '/login';
        return false;
    }

    if (auth.getToken()) {
        let currentRoleInlocalStorgae = [];
        if (null !== localStorage.getItem('userRoles')) {
            currentRoleInlocalStorgae = localStorage.getItem('userRoles').split(',');
        }
        const ArrayRoleFromToken = auth.parseJwt().resource_access.noc.roles

        return ArrayRoleFromToken.length === currentRoleInlocalStorgae.length &&
            ArrayRoleFromToken.every(function (element) {
                return currentRoleInlocalStorgae.indexOf(element) !== -1;
            })
    }

    return false;
}
