import React, {Component} from 'react';
import './Contentheader.css'

class contentHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: null
        }
    }

    openSidebar() {
        if (window.innerWidth <= 767) {
            if (this.state.isOpen === null || this.state.isOpen === false) {
                this.setState({
                    isOpen: true
                })

                document.body.classList.add('sidebar-open');
            }

            if (this.state.isOpen === true) {
                document.body.classList.remove('sidebar-open');
                this.setState({
                    isOpen: false
                })
            }
        } else {
            document.body.classList.toggle('sidebar-collapse')
        }
    }

    fullScreen() {
        this.openSidebar();

        var element = document.getElementsByClassName('main-header')[0];

        if (element === undefined) {
            return;
        }

        if (element.style.display === 'none') {
            element.style.display = 'block';
            return;
        }

        element.style.display = "none";
    }

    render() {
        return (
            <div>
                <section className="action-header">
                    <button onClick={this.openSidebar.bind(this)} style={{marginRight: 10}} className='btn btn-default btn-sm'>
                        <i className="fa fa-bars" aria-hidden="true"/>
                    </button>
                    <button onClick={this.props.refreshClicked} className='btn btn-default btn-sm pull-right'>
                        <i className="fa fa-refresh" aria-hidden="true"/>
                    </button>
                    <button onClick={this.fullScreen.bind(this)} style={{marginRight: 10}} id='fullscreen' className='btn btn-default btn-sm pull-right'>
                        <i className="fa fa-television" aria-hidden="true"/>
                    </button>
                </section>
                <section className="content-header">
                    <h1>
                        {this.props.nameHeader}
                        <small>
                            {this.props.smallHeader ? this.props.smallHeader : null}
                        </small>
                    </h1>
                </section>
            </div>
        )
    };
}

export default contentHeader;
