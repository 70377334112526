import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import Aux from '../Aux/Aux';
import Sidebar from '../../components/Sidebar/Sidebar';
import Content from '../../components/Content/Content';
import Footer from '../../components/Footer/Footer';
const NavBar = React.lazy(() => import('../../components/Navigation/NavBar'));

class Layout extends Component {
    isLoginPage = () => {
        return (this.props.location.pathname === '/login');
    };

    isRootPage = () => {
        return (this.props.location.pathname === '/');
    };

    hasSidebar = () => {
        return !this.props.noSidebar;
    };

    getMinHeight = () => {
        return window.innerHeight;
    };

    render() {
        return (
            <Aux>
                {this.isLoginPage() || this.isRootPage() ? (
                    <div className='hold-transition login-page'>
                        {this.props.children}
                    </div>
                ) : (
                    <div className="wrapper" style={{minHeight: this.getMinHeight()}}>
                        <div className="main-header">
                            <React.Suspense fallback={<div>Loading... </div>}>
                                <NavBar />
                            </React.Suspense>
                        </div>
                        <Sidebar auth={this.props.auth} />
                        <Content>
                            {this.props.children}
                        </Content>
                        <Footer/>
                    </div>
                )}
            </Aux>
        );
    };
}

export default withRouter(Layout);
