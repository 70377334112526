import {withRouter} from 'react-router-dom';
import AbstractByAircraft from './AbstractByAircraft';
import React from "react";

class RemoteConnexionByAircraft extends AbstractByAircraft {

    constructor(props) {
        super(props);
        this.specificUri = 'remote_connexions';
        this.state = {
            resList: [],
            loading: true,
            currentPageNumber: 1,
            orderBy: 'ASC',
            currentFieldOrder: null,
            navigation: {
                totalItems: 0,
                first: '',
                next: '',
                last: '',
                totalPage: 0
            },
            error: {
                status: false,
                message: ''
            },
            anchor: '#rmc',
            fieldsName: [
                'Port',
                'endpoint',
                'Started At',
                'Last Event Received At',
                'Status'
            ]
        };
    }

    getProperty = (field) => {
        let property;

        switch (field) {
            case 'Port':
                property = 'portId';
                break;
            case 'Endpoint':
                property = 'endpoint'
                break;
            case 'Started At':
                property = 'startedAt';
                break;
            case 'Last Event Received At':
                property = 'lastEventReceivedAt';
                break;
            case 'Status':
                property = 'status';
                break;
            default:
                property = '';
        }

        return property;
    };

    fieldClicked = (fields) => {
        if (this.props.clickable) {
            const remoteConnexionUuid = fields[0]
            this.props.history.push(`/${this.props.icao}/remote-connection/${remoteConnexionUuid}`)
        }
        return true
    }

    getFields = () => {
        let fields = [];
        const resList = [...this.state.resList];

        resList.forEach(res => {
            let status = (
                <span className='label label-info'>unknown</span>
            );

            if (res.status === 'opened') {
                status = (
                    <span className='label label-success'>{res.status}</span>
                );
            }

            if (res.status === 'closed') {
                status = (
                    <span className='label label-info'>{res.status}</span>
                );
            }

            fields.push([
                res.uuid,
                res.portId,
                res.endpoint ? res.endpoint : 'default',
                res.startedAt,
                res.lastEventReceivedAt,
                status
            ])
        });

        return fields;
    };
}

export default withRouter(RemoteConnexionByAircraft);
