import React, {Component} from 'react'
import {Link} from "react-router-dom";

class Unauthorized extends Component {

    render() {

        return (
            <div className='error-page' style={{paddingTop: '5%'}}>
                <h2 className='headline text-yellow' style={{marginRight:10}}>
                    403
                </h2>
                <h3>
                    <i className="fa fa-warning text-yellow"/>
                    Oops! You don't have permissions.
                </h3>
                <p>
                    You don't have enough permissions to have access to this page.<br/>
                    Please contact Display Interactive support (support@display.aero) if needed. <Link to='/'>return to dashboard.</Link>
                </p>
                <img alt='404' style={{width: '50%', marginBottom: 20, marginTop: 20}} src={require('../Page404/oops-404.png')}/>
            </div>
        )
    }

}

export default (Unauthorized)
