import {withRouter} from 'react-router-dom';

import AbstractByAircraft from './AbstractByAircraft';

class PiwikLogsByAircraft extends AbstractByAircraft {
    constructor(props) {
        super(props);
        this.specificUri = 'piwik_injector_logs';
        this.state = {
            resList: [],
            loading: true,
            currentPageNumber: 1,
            orderBy: 'ASC',
            currentFieldOrder: null,
            navigation: {
                totalItems: 0,
                first: '',
                next: '',
                last: '',
                totalPage: 0
            },
            error: {
                status: false,
                message: ''
            },
            anchor: '#logs',
            fieldsName: [
                'File Name',
                'Is Processed',
                'Processed At',
                'Created At',
                'Updated At',
            ]
        };
    }

    getProperty = (field) => {
        let property;

        switch (field) {
            case 'File Name':
                property = 'fileName';
                break;
            case 'Is Processed':
                property = 'isProcessed';
                break;
            case 'Processed At':
                property = 'processedAt';
                break;
            case 'Created At':
                property = 'createdAt';
                break;
            case 'Updated At':
                property = 'updatedAt';
                break;
            default:
                property = '';
        }

        return property;
    };

    getFields = () => {
        let fields = [];
        const resList = [...this.state.resList];

        resList.forEach(res => {
            fields.push([
                res.id,
                res.fileName,
                res.isProcessed ? 'True' : 'False',
                res.processedAt,
                res.createdAt,
                res.updatedAt,
            ])
        });

        return fields;
    };
}

export default withRouter(PiwikLogsByAircraft);
