import {withRouter} from 'react-router-dom';

import AbstractByAircraft from './AbstractByAircraft';
import React from "react";

class MediaSyncsByAircraft extends AbstractByAircraft {
    constructor(props) {
        super(props);
        this.specificUri = 'media_syncs';
        this.state = {
            resList: [],
            loading: true,
            currentPageNumber: 1,
            orderBy: 'ASC',
            currentFieldOrder: null,
            navigation: {
                totalItems: 0,
                first: '',
                next: '',
                last: '',
                totalPage: 0
            },
            error: {
                status: false,
                message: ''
            },
            anchor: '#sync4',
            fieldsName: [
                'Part Number',
                'Frequency',
                'startedAt',
                'finishedAt',
                'Status',
            ]
        };
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search)
        let currentFieldOrder;
        let orderBy = 'ASC'

        if (query.has('order[partNumber]')) {
            currentFieldOrder = 'Part Number'
            orderBy = query.get('order[partNumber]')
        }

        if (query.has('order[status]')) {
            currentFieldOrder = 'Status'
            orderBy = query.get('order[status]')
        }

        if (query.has('order[startedAt]')) {
            currentFieldOrder = 'Started At'
            orderBy = query.get('order[startedAt]')
        }

        if (query.has('order[finishedAt]')) {
            currentFieldOrder = 'Finished At'
            orderBy = query.get('order[finishedAt]')
        }

        if (query.has('order[mediaSyncFrequency.name]')) {
            currentFieldOrder = 'Frequency'
            orderBy = query.get('order[mediaSyncFrequency.name]')
        }

        this.setState({
            currentFieldOrder,
            orderBy
        })

        this.getContentPage('?transportUnit.uuid=' + this.props.transportUnitUuid);
    }

    getProperty = (field) => {
        const properties = {
            'Part Number': 'partNumber',
            'Status': 'status',
            'Frequency': 'mediaSyncFrequency.name',
            'Started At': 'startedAt',
            'Finished At': 'finishedAt'
        };

        return properties.hasOwnProperty(field) ? properties[field] : '';

    };

    fieldClicked = (fields) => {
        if (this.props.clickable) {
            const heartBeatUuid = fields[0]
            this.props.history.push(`/${this.props.icao}/media_sync/${heartBeatUuid}`)
        }
        return true
    }

    getFields = () => {
        let fields = [];
        const resList = [...this.state.resList];

        resList.forEach(res => {

            let status = (
                <span className='label label-info'>start</span>
            );

            if (res.status === 'done') {
                status = (
                    <span className='label label-success'>done</span>
                );
            }

            if (res.status === 'error') {
                status = (
                    <span className='label label-danger'>error</span>
                );
            }

            fields.push([
                res.uuid,
                res.partNumber,
                res.mediaSyncFrequency.name,
                res.startedAt,
                res.finishedAt,
                status,
            ])
        });

        return fields;
    };
}

export default withRouter(MediaSyncsByAircraft);
