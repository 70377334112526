import React from 'react';
import {grantPermission} from "../../../../../hoc/RoleService/RoleService";
import SidebarMenuItem from './SidebarMenuItem';

const SideBarMenu = (props , {auth}) => {
    let icao = window.location.pathname.split("/")[1];

    let dashboard = ""
    let aircraft = ""
    let airmap = ""
    let remoteMaintenance = ""
    let slideshow = ""
    let flights = ""

    if (grantPermission('dashboard', auth)) {
        dashboard = (
            <>
                <SidebarMenuItem link={'/' + icao} icon='fa fa-tachometer' itemName='Dashboard'/>
            </>
        )
    }

    if (grantPermission('aircraft_list', auth)) {
        aircraft = (
            <>
                <SidebarMenuItem link={'/' + icao +'/aircraft'} icon='fa fa-plane' itemName='Aircraft'/>
            </>
        )
    }

    if (grantPermission('flight', auth)) {
        flights = (
            <>
                <SidebarMenuItem link={'/' + icao +'/flights'} icon='fa fa-map-signs' itemName='Flights'/>
            </>
        )
    }

    if (grantPermission('air_map', auth)) {
        airmap = (
            <>
                <SidebarMenuItem link={'/' + icao +'/airmap'} icon='fa fa-map' itemName='Airmap'/>
            </>
        )
    }

    if (grantPermission('rmc', auth)) {
        remoteMaintenance = (
            <>
                <SidebarMenuItem link={'/' + icao +'/remote_connection'} icon='fa fa-server' itemName='Remote Maintenance'/>
            </>
        )
    }

    if (grantPermission('slideshow', auth)) {
        slideshow = (
            <>
                <SidebarMenuItem link={'/' + icao +'/slideshow'} icon='fa fa-television' itemName='Slideshow'/>
            </>
        )
    }

    return (
        <ul className="sidebar-menu" data-widget="tree">
            <li className="header">MAIN NAVIGATION</li>

            {dashboard}
            {aircraft}
            {flights}
            {airmap}
            {remoteMaintenance}
            {slideshow}
        </ul>
    )
};

export default SideBarMenu;
