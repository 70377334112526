import React from 'react';

const button = (props) => (
    <button
        onClick={props.clicked}
        type="button"
        className={props.buttonClass + ' ' + props.status}
        aria-label="Left Align">
        <i className={props.icon} aria-hidden="true" /> {props.content}
    </button>
);

export default button;
