import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// Importing the Bootstrap CSS 
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';

import './scripts/style/style.scss';
import 'admin-lte/dist/css/skins/skin-blue.min.css';
import 'jquery/src/jquery';
import 'admin-lte/dist/js/adminlte.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';

import App from './App';

const app = (
    <BrowserRouter>
        <App />
    </BrowserRouter>
);
ReactDOM.render(app, document.getElementById('root'));

navigator.serviceWorker.getRegistrations().then(function(registrations) {
    for(let registration of registrations) {
        registration.unregister()
    } })
