import React, {Component} from 'react';

import Spinner from "../../UI/Spinner/Spinner";
import Table from "../../UI/Table/Table";
import TableFooter from "../../UI/Table/TableFooter";
import {CSSTransitionGroup} from 'react-transition-group';
import {FormatTime} from "../../../hoc/FormatTime";
import {GetOrder} from "../../../hoc/GetOrder";

class AbstractByAircraft extends Component {
    constructor(props) {
        super(props);
        this.specificUri = '';
        this.Auth = this.props.auth;
        // instantiation prohibited (simulate abstract Class) test
        if (new.target === AbstractByAircraft) throw TypeError("Cannot instantiate abstract class \"AbstractByAircraft\"");
    }

    componentDidMount() {
        this.getContentPage('?transportUnit.uuid=' + this.props.transportUnitUuid);
    }

    componentWillUpdate() {
        this.getCurrentPageNumber();
    }

    navigationClickedHandler = (pageId) => {
        this.getList(pageId);
    };

    /**
     * Implementation required - Like abstractMethod
     */
    getProperty() {
        throw new Error('You have to implement the method getProperty!');
    }


    /**
     * Implementation required
     */
    getFields() {
        throw new Error('You have to implement the method getFields!');
    }

    getCurrentPageNumber() {
        let currentPageNumber = this.state.currentPageNumber;

        const query = new URLSearchParams(this.props.location.search);

        for (let param of query.entries()) {
            if (param[0] === 'page') {
                currentPageNumber = parseInt(param[1], 10);
            }
        }

        if (this.state.currentPageNumber !== currentPageNumber) {
            this.setState({ currentPageNumber: currentPageNumber });
        }
    }

    fieldClicked = (field) => {
        console.log('clicked on ' + field);
    };

    getTotalPages(query) {
        if (query === undefined || !query.includes('page=')) {
            return 1;
        }

        return parseInt(query.split('page=')[1], 10);
    }

    getList(pageId, property = null, order = 'ASC') {
        this.setState({ loading: true });
        let newQuery = '';

        let hash = null;
        if (this.props.location.hash) {
            hash = this.props.location.hash
        }

        if (property) {
            newQuery += '?order[' + property + ']=' + order;

            this.props.history.push('/' + this.props.icao + '/aircraft/' + this.props.transportUnitUuid + newQuery + hash);
        } else {
            const query = new URLSearchParams(this.props.location.search);

            var out = [];
            for (let param of query.entries()) {
                if (param[0] === 'page') {
                    out.push(param[0] + '=' + encodeURIComponent(pageId));
                    continue;
                }
                out.push(param[0] + '=' + encodeURIComponent(param[1]));
            }
            newQuery = out.join('&')
        }

        if (!this.props.location.search.includes('page=')) {
            newQuery += '&page=' + pageId;
        }

        if (newQuery.substring(0, 1) !== '?') {
            newQuery = '?' + newQuery;
        }

        this.getContentPage(newQuery + '&transportUnit.uuid=' + this.props.transportUnitUuid);
    }

    fetchData = (query) => {
        this.auth.fetch(query)
            .then(res => {

                if (res.code === 401) {
                    this.props.history.push({
                        pathname: '/login',
                        state: { previousUrl: this.props.location.pathname }
                    });
                }
                let error = '';
                if (res['hydra:description'] && (
                    res['hydra:description'] === 'Not Found'
                    || res['hydra:description'] === 'Access Denied.')) {
                    error = {
                        status: true,
                        message: res['hydra:description']
                    }
                }
                this.setState(
                    {
                        data: res,
                        loading: false,
                        error: error
                    }
                );
            })
            .catch(e => {
                this.setState({
                    loading: false,
                    error: {
                        status: true,
                        message: e.toString()
                    }
                });
            });
    };

    getContentPage = (query, filterByIcao) => {

        if (filterByIcao) {
            if (query.length > 1) {
                query += '&' + filterByIcao
            } else {
                query = '?' + filterByIcao
            }
        }

        if (this.state.anchor === this.props.location.hash) {
            var regex = /page=\d+/g;
            var matches = query.match(regex);

            if (matches === null) {
                query = query + this.props.location.search.replace('?', '&')
            }
        }

        this.Auth.fetch(this.specificUri + query)
            .then(res => {
                if (res.code === 401) {
                    this.props.history.push({
                        pathname: '/login',
                        state: { previousUrl: this.props.location.pathname }
                    });
                }
                let error = '';
                if (res['hydra:description'] && (
                    res['hydra:description'] === 'Not Found'
                    || res['hydra:description'] === 'Access Denied.')) {
                    error = {
                        status: true,
                        message: res['hydra:description']
                    }
                }

                let partial = false
                let partialURI = ["/api/aircraft_heart_beats"]

                if (partialURI.includes(res['@id'])) {
                    partial = true
                }

                this.setState(
                    {
                        resList: res['hydra:member'] ? res['hydra:member'] : [],
                        navigation: {
                            totalItems: res['hydra:totalItems'] ? res['hydra:totalItems'] : 0,
                            first: res['hydra:view'] ? res['hydra:view']['hydra:first'] : null,
                            next: res['hydra:view'] ? res['hydra:view']['hydra:next'] : null,
                            last: res['hydra:view'] ? res['hydra:view']['hydra:last'] : null,
                            totalPage: this.getTotalPages(res['hydra:view'] ? res['hydra:view']['hydra:last'] : "1"),
                            partial: partial
                        },
                        loading: false,
                        error: error
                    }
                );

            })
            .catch(e => {
                this.setState({
                    loading: false,
                    error: {
                        status: true,
                        message: e.toString()
                    }
                });
            });
    };

    getOrder(order, queries, property) {
        return GetOrder(order, queries, property);
    }

    refreshHandler = () => {
        this.setState({
            loading: true,
            error: {
                status: false,
                message: ''
            }
        });
        this.getContentPage('?transportUnit.uuid=' + this.props.transportUnitUuid);
    };

    tableHeadClickedHandler = (field) => {
        if (field === 'ACK') {
            return
        }

        const queries = new URLSearchParams(this.props.location.search).entries();
        const property = this.getProperty(field);
        const order = this.getOrder(this.state.orderBy, queries, property);

        this.setState({ orderBy: order, currentPageNumber: 1, currentFieldOrder: field });
        this.getList(1, property, order);
    };

    formatTime = (timerTime) => {
        return FormatTime(timerTime);
    }

    render() {
        const fields = this.getFields();

        let content = (
            <div className="box-body">
                <Spinner />
            </div>
        );

        if (!this.state.loading) {
            content = (
                <div className="box-body col-md-12">
                    <Table
                        tableHeadClicked={field => this.tableHeadClickedHandler(field)}
                        transportUnitClicked={this.fieldClicked}
                        fieldsName={this.state.fieldsName}
                        fields={fields}
                        orderBy={this.state.orderBy}
                        currentFieldOrder={this.state.currentFieldOrder}
                        clickable={this.props.clickable}
                        tableTitle={this.state.tableTitle}
                        messageTitle={this.state.messageTitle}
                        numberOfResult={this.state.resList.length}
                        showBoxSolid={this.state.showBoxSolid}
                        boxColor={this.state.boxColor}
                        boxIcon={this.state.boxIcon}
                        boxMessageTitle={this.state.boxMessageTitle}
                        boxMessageContent={this.state.boxMessageContent}
                    />
                    <TableFooter
                        transportUnits={this.state.transportUnits}
                        navigation={this.state.navigation}
                        location={this.props.location}
                        hash={this.props.location.hash}
                        currentPage={this.state.currentPageNumber}
                        navigationClicked={(pageId) => this.navigationClickedHandler(pageId)}
                        numberOfResult={this.state.resList.length}
                        anchor={this.state.anchor}
                    />
                </div>
            );
        }

        if (this.state.error.status) {
            content = (
                <div className="alert alert-danger alert-dismissible">
                    <button onClick={this.refreshHandler} type="button" className="close"
                        aria-hidden="true"><i className='fa fa-refresh' /></button>
                    <h4><i className="icon fa fa-ban" /> {this.state.error.message}</h4>
                </div>
            );
        }

        return (
            <div>
                <CSSTransitionGroup
                    transitionName="popup"
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={300}>
                    {this.state.popup}
                </CSSTransitionGroup>
                {content}
            </div>
        );
    }
}

export default AbstractByAircraft;
