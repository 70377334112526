import React from 'react';

const legalNotice = () => (
    <section className="content ">
        <div className="row">
            <div className="col-xs-12">
                <h2>Legal Notice</h2>
                <div className="box box-body">
                    <p>The website “Display Interactive - Network Operations Center” website (<a href="https://noc.cloud.ugo.aero" target="_blank" rel="noopener noreferrer">NOC - Dashboard! </a>) belongs and is operated by Display Interactive SAS, a company incorporated under the laws of France, with unique identification number 494 141 187 by RCS Nanterre. Its registered office is located at 106 avenue Marx-Dormoy, 92120 Montrouge, France. Its Intracom VAT number is FR24 494 141 187.</p>
                    <p>Display Interactive teams can be reached by mail at
                        <a href="mailto:support@display.aero" target="_blank" rel="noopener noreferrer"> Mail to support@display.aero</a>.</p>
                    <p>Hosting of this site is provided by Amazon Web Services EMEA SARL (AWS Europe), 31 PLACE DES COROLLES, TOUR CARPE DIEM, 92400 COURBEVOIE, FRANCE SIREN : 831 001 334 • RCS Nanterre • APE : 6311Z • TVA: FR30831001334. All data are processed and stored in European Union.</p>
                </div>
            </div>
        </div>
    </section>
);

export default legalNotice;
