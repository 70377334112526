import React from 'react';

const privacyPolicy = () => (
    <section className="content ">
        <div className="row">
        <div className="col-xs-12">
            <h1>Privacy & cookies Policy</h1>
            <div className="box box-body"> 

                <p>Display Interactive has the utmost respect for your privacy and is committed to protecting, safeguarding and maintaining the personal information which you might provide to handle these specific requests.</p>
                <p>This Privacy and Cookie Policy, is part of our Terms & Conditions of Use, and intended to make sure you understand why and how we might collect, use and store this data.</p>
                <p>As a User, you agree that:</p>
                <p>
                    – In the eventuality that you input personal data on the Site, you consent to the collection, processing, storage and transfer in order to handle your request, and that all data provided are accurate and up to date.
                </p>
                <p>
                    – You are aware of our Cookie Policy, in order to enable you to navigate the site correctly, and are informed about what cookies are, the type of cookies used and how to accept, disable or delete them.
                </p>
                <h2>Privacy policy</h2>
                <p>
                    This Privacy Policy applies to the Display Interactive - Network Operations Center website (the Site) and sets how Display will process any personal data collected from or provided by you in compliance with the Regulation (EU) 2016/679 dated April 27, 2016 on the protection of natural persons with regard to the processing, storage and free movement of personal data referred to as the «General Data Protection Regulation» or «GDPR».
                </p>
                <p>
                    As a reminder, Display reserves the right to update the T&C including the Privacy Policy and the Cookies Policy at any time and without notice to users. To access the most recent version of the T&C, the Privacy Policy or the Cookies Policy please click on the footnote links.
                </p>
                <p>
                    For the purposes of this Privacy Policy, Display will act as data controller as defined in the GDPR, with the Data Protection Officer as the point of contact under the below references:
                </p>
                <p>
                    – Display Interactive, 106 avenue Marx Dormoy, 92120 Montrouge, France
                </p>
                <p>
                    – Data Protection Officer: dpo@display.aero
                </p>
                <p>Data collected, purpose and use of data</p>
                <p>
                    We collect only aggregated/anonymous information regarding your activities from our Website as detailed in our Cookie Policy.
                </p>
                <p>
                    In addition, and this is detailed in our Cookie Policy, we may also collect aggregated/anonymous information regarding your activities from our Website.
                </p>
                <p>
                    Such data is collected and processed in order to improve our website thanks to traffic analysis supported by cookies.
                </p>
                <p>
                    The reasons we collect such data is therefore considered as by consent or out of legitimate interests, as defined under the concept of ‘lawfulness of processing’.
                </p>
                <p>
                    Collected data will be stored until its business objective is no longer valid, unless your initial consent for sharing them with us is withdrawn, which can be done by sending such request to our Data Protection Officer.
                </p>
                <p>
                    Your personal Data shall be stored uniquely within the European Economic Area (EEA) and shall not be transferred to any country outside the EEA. Display is committed to protect your personal data and will take all necessary steps to ensure a confidential and secure treatment, in accordance with this Privacy Policy and the GDPR in their more recent versions.
                </p>
                <p>
                    Display may disclose your personal data :
                </p>
                <p>
                    – If required by law, or request from public or governmental authorities within or outside France and in respect of international treaties,
                </p>
                <p>
                    – If required by competent Data Protection Authorities,
                </p>
                <p>
                    – if Display determines necessary to enforce the T&C and this Privacy Policy or protect its operations or the Site users.
                </p>
                <p>
                    Your data protection rights
                </p>
                <p>
                    By following GDPR policy to protect your personal data, Display guarantees you the following rights without restriction:
                </p>
                <p>
                    – The right of access to your personal data, meaning the right to know whether your personal data is being processed and the right to access it in a commonly used and machine-readable format (GDPR Article 15),
                </p>
                <p>
                    – The right to rectification of your personal data, meaning when your personal data are inaccurate, the right to have them corrected (GDPR Article 16),
                </p>
                <p>
                    – The right to erasure of your personal data or right to be forgotten as but not limited when your personal data has been made public (GDPR Article 17),
                </p>
                <p>
                    – The right to restriction of the processing of your personal data, meaning your right to limit the processing of your personal data (GDPR Article 18),
                </p>
                <p>
                    – The right to object, meaning you have the right to object the personal data processing to be done or going on (GDPR Article 21),
                </p>
                <p>
                    – The right to be informed, meaning that you have the right to know what of your personal data have undergone an action by Display, and also who are these recipients who got to see your data (GDPR article 19),
                </p>
                <p>
                    – The right to data portability, meaning that you have the right to transmit your personal data to another controller. (GDPR Article 20),
                </p>
                <p>
                    – The right not to be subject to a decision resulting solely from automated processing, including profiling. (GDPR Article 22).
                </p>
                <p>
                    You may exercise your rights by contacting Display’s Data Protection Officer as detailed previously.
                </p>
                <h2>Cookie Policy</h2>
                <p>
                    When you visit the Site, one or more cookies (a small text file) may be installed on your device. These cookies allow the Site to authorize secured authentication, to remember your actions and preferences so that you do not have to reconfigure them each time you return to the Site. Cookies may be developed by the Site or by third parties and may be stored only during the session or for a longer period of time. They are required to secure your connection and to authenticate yourself.
                </p>
                <p>
                    When you enter on the website, we request your consentment to accept cookies. Even if no personal data or identification is provided by cookies, you can withdraw your consent to cookies at any time by deleting cookies already installed, blocking them, or preventing the installation of new cookies by configuring your browser. To do so, please apply the procedures detailed by the browser you are using.
                </p>
                <p>
                    Shall you decide to delete or prevent the use of all or part of the cookies on the site, you undertake that your experience of the site might be altered or even usage might be prevented.
                </p>

            </div>
        </div>
        </div>

    </section>
);

export default privacyPolicy;
