import React from 'react';
import { withRouter } from 'react-router-dom';
import '../Aircrafts/AircraftDetail/AircraftDetail.css';
import CompanyService from "../../hoc/CompanyService/CompanyService";
import Table from "../../components/UI/Table/Table";
import { grantPermission } from "../../hoc/RoleService/RoleService";
import TableFooter from "../../components/UI/Table/TableFooter";
import Spinner from "../../components/UI/Spinner/Spinner";
import SearchInput from "../../components/UI/Input/SearchInput";
import SelectInput from "../../components/UI/Input/SelectInput";
import MessageInfo from "../../components/Messages/MessageInfo";
import Aux from "../../hoc/Aux/Aux";
import ContentHeader from "../../components/Content/ContentHeader/Contentheader";
import DateInput from "../../components/UI/Input/DateInput";
import moment from "moment";

const divStyleCity = {
    fontWeight: 'bold'
};

class Flights extends React.Component {
    constructor(props) {
        super(props);
        this.Auth = this.props.auth;
        this.companyService = new CompanyService();
        this.specificUri = 'flights';
        this.state = {
            icao: '',
            error: {
                status: false,
                message: ''
            },
            results: [],
            navigation: {
                totalItems: 0,
                first: '',
                next: '',
                last: '',
                totalPage: 0
            },
            fieldsName: [
                'Company',
                'Registration',
                'Flight Number',
                'From',
                'To',
                'Departure At',
                'Status',
                'System status',
            ],
            query: { transportUnit_operatorCompany_name: null, transportUnit_registration: null, flightNumber: null },
            searchFieldRegistration: '',
            searchFieldFlightNumber: '',
            searchFieldDepartureAt: '',
            selectFieldStatus: '',
            currentPageNumber: 1,
            loading: true,
        };
    }

    componentDidMount() {
        const { icao } = this.props.match.params;
        this.setState({ 'icao': icao })

        this.companyService.redirectUserIfNotDisplayUser(icao, this.props.auth)

        document.body.classList.value = 'skin-blue sidebar-mini'
        const query = new URLSearchParams(this.props.location.search)

        const searchFieldFlightNumber = query.has('flightNumber') ? query.get('flightNumber') : ''
        const searchFieldDepartureAt = query.has('departureAt') ? query.get('departureAt') : ''
        const searchFieldRegistration = query.has('transportUnit.registration') ? query.get('transportUnit.registration') : ''
        const currentPageNumber = query.has('page') ? parseInt(query.get('page')) : 1

        let currentFieldOrder = ''
        let orderBy = 'ASC'

        if (query.has('order[transportUnit.registration]')) {
            currentFieldOrder = 'Registration'
            orderBy = query.get('order[transportUnit.registration]')
        }
        if (query.has('order[transportUnit.operatorCompany.name]')) {
            currentFieldOrder = 'Company'
            orderBy = query.get('order[transportUnit.operatorCompany.name]')
        }
        if (query.has('order[flightNumber]')) {
            currentFieldOrder = 'Flight Number'
            orderBy = query.get('order[flightNumber]')
        }
        if (query.has('order[fromAirport.city]')) {
            currentFieldOrder = 'From'
            orderBy = query.get('order[fromAirport.city]')
        }
        if (query.has('order[toAirport.city]')) {
            currentFieldOrder = 'To'
            orderBy = query.get('order[toAirport.city]')
        }
        if (query.has('order[departureAt]')) {
            currentFieldOrder = 'Departure At'
            orderBy = query.get('order[departureAt]')
        }
        if (query.has('order[transportUnitStatus]')) {
            currentFieldOrder = 'Status'
            orderBy = query.get('order[transportUnitStatus]')
        }

        this.setState({
            searchFieldFlightNumber,
            searchFieldRegistration,
            searchFieldDepartureAt,
            currentPageNumber,
            currentFieldOrder,
            orderBy
        })

        this.getContentPage(this.props.location.search, this.companyIcaoFilter(icao));
    }

    companyIcaoFilter = (icao) => {
        let additionalQuery = '';
        if (icao && !this.companyService.isDisplayIcao(icao)) {
            additionalQuery = 'transportUnit.operatorCompany.icao=' + icao.toUpperCase()
        }
        return additionalQuery
    }

    getContentPage = (query, filterByIcao) => {

        const hash = this.props.search ? this.props.location.hash : ''
        query += hash;

        if (filterByIcao) {
            if (query.length > 1) {
                query += '&' + filterByIcao
            } else {
                query = '?' + filterByIcao
            }
        }

        this.Auth.fetch(this.specificUri + query)
            .then(res => {
                if (res.code === 401) {
                    this.props.history.push({
                        pathname: '/login',
                        state: { previousUrl: this.props.location.pathname }
                    });
                }
                let error = '';
                if (res['hydra:description'] && (
                    res['hydra:description'] === 'Not Found'
                    || res['hydra:description'] === 'Access Denied.')) {
                    error = {
                        status: true,
                        message: res['hydra:description']
                    }
                }

                this.setState(
                    {
                        results: res['hydra:member'],
                        navigation: {
                            totalItems: res['hydra:totalItems'] ? res['hydra:totalItems'] : 0,
                            first: res['hydra:view'] ? res['hydra:view']['hydra:first'] : null,
                            next: res['hydra:view'] ? res['hydra:view']['hydra:next'] : null,
                            last: res['hydra:view'] ? res['hydra:view']['hydra:last'] : null,
                            totalPage: this.getTotalPages(res['hydra:view'] ? res['hydra:view']['hydra:last'] : "1")
                        },
                        loading: false,
                        error: error
                    }
                );

            })
            .catch(e => {
                this.setState({
                    loading: false,
                    error: {
                        status: true,
                        message: e.toString()
                    }
                });
            });
    };

    getTotalPages(query) {
        if (query === undefined || !query.includes('page=')) {
            return 1;
        }

        return parseInt(query.split('page=')[1], 10);
    }

    isEmpty(str) {
        return (!str || str.length === 0);
    }

    onSearchChangeHandler = (event, placeholder) => {
        let value;
        let lastQuery = '?';

        switch (placeholder) {
            case 'Departure At':
                value = moment(event).format("YYYY-MM-DD");
                if (event === null) {
                    value = ''
                }
                lastQuery += `&departureAt=${value}`;
                if (this.state.searchFieldRegistration) {
                    lastQuery += `&transportUnit.registration=${this.state.searchFieldRegistration}`;
                }
                if (this.state.searchFieldFlightNumber) {
                    lastQuery += `&flightNumber=${this.state.searchFieldFlightNumber}`;
                }
                this.setState({
                    searchFieldDepartureAt: value,
                    currentPageNumber: 1,
                    currentFieldOrder: ''
                });
                break;
            case 'Registration':
                value = event.target.value;
                lastQuery += `&transportUnit.registration=${value}`;
                if (this.state.searchFieldDepartureAt) {
                    lastQuery += `&departureAt=${this.state.searchFieldDepartureAt}`;
                }
                if (this.state.searchFieldFlightNumber) {
                    lastQuery += `&flightNumber=${this.state.searchFieldFlightNumber}`;
                }
                this.setState({
                    searchFieldRegistration: value,
                    currentPageNumber: 1,
                    currentFieldOrder: ''
                });
                break;
            case 'Flight Number':
                value = event.target.value;
                lastQuery += `&flightNumber=${value}`;
                if (this.state.searchFieldRegistration) {
                    lastQuery += `&transportUnit.registration=${this.state.searchFieldRegistration}`;
                }
                if (this.state.searchFieldDepartureAt) {
                    lastQuery += `&departureAt=${this.state.searchFieldDepartureAt}`;
                }
                this.setState({
                    searchFieldFlightNumber: value,
                    currentPageNumber: 1,
                    currentFieldOrder: ''
                });
                break;
            default:
                break
        }

        this.props.history.push('/' + this.state.icao + '/flights' + lastQuery);
        this.getContentPage(lastQuery, this.companyIcaoFilter(this.state.icao));
    };

    onSelectChangeHandler = (event) => {
        const targetValue = event.target.value
        let query = this.state.searchFieldFlightNumber && '&flightNumber=' + this.state.searchFieldFlightNumber
        query += this.state.searchFieldDepartureAt && '&departureAt' + this.state.searchFieldDepartureAt
        query += this.state.searchFieldRegistration && '&transportUnit.registration=' + this.state.searchFieldRegistration

        if (targetValue === 'default') {
            this.props.selectFieldStatus !== '' && this.setState({ selectFieldStatus: '', currentPageNumber: 1 })
        } else {
            this.setState({ selectFieldStatus: targetValue, currentPageNumber: 1 })
            query += '&transportUnitStatus=' + targetValue
        }

        this.props.history.push('/' + this.state.icao + '/flights?' + query)
        this.getContentPage('?' + query, this.companyIcaoFilter(this.state.icao))
    }

    getProperty = (field) => {
        let property;

        switch (field) {
            case 'Company':
                property = 'transportUnit.operatorCompany.name';
                break;
            case 'Registration':
                property = 'transportUnit.registration';
                break;
            case 'Flight Number':
                property = 'flightNumber';
                break;
            case 'From':
                property = 'fromAirport.city';
                break;
            case 'To':
                property = 'toAirport.city';
                break;
            case 'Departure At':
                property = 'departureAt';
                break;
            case 'Status':
                property = '';
                break;
            case 'System Status':
                property = 'transportUnitStatus';
                break;
            default:
                property = '';
        }

        return property;
    };

    navigationClickedHandler = (pageId) => {
        this.getList(pageId);
    };

    tableHeadClickedHandler = (field) => {
        const queries = new URLSearchParams(this.props.location.search).entries();
        const property = this.getProperty(field);

        let order = this.state.orderBy;
        let currentFilter = '';

        for (let param of queries) {
            if (param[0] === 'order[' + property + ']') {
                if (order === 'DESC') {
                    order = 'ASC';
                } else {
                    order = 'DESC';
                }
            }

            if (param[0] === 'transportUnit.operatorCompany.name' ||
                param[0] === 'transportUnit.registration' ||
                param[0] === 'flightNumber' ||
                param[0] === 'fromAirport.city' ||
                param[0] === 'toAirport.city' ||
                param[0] === 'transportUnitStatus' ||
                param[0] === 'departureAt') {
                currentFilter += currentFilter ? '&' + param[0] + '=' + param[1] : param[0] + '=' + param[1];
            }
        }
        if (order === this.state.orderBy && order === 'DESC') {
            order = 'ASC';
        }

        this.setState({ orderBy: order, currentPageNumber: 1, currentFieldOrder: field });
        this.getList(1, property, order, currentFilter);
    };

    getList(pageId, property = null, order = 'ASC', currentFilter = null) {
        this.setState({ loading: true });
        let newQuery = '';

        newQuery += 'page=' + pageId;

        if (currentFilter) {
            newQuery += '&' + currentFilter;
        }

        if (property) {
            newQuery += '&order[' + property + ']=' + order;
        } else {
            const query = new URLSearchParams(this.props.location.search);
            for (let param of query.entries()) {
                if (param[0] === 'page') {
                    continue;
                }

                if (param[1]) {
                    newQuery += '&' + param[0] + '=' + param[1];
                } else {
                    newQuery += '&' + param[0];
                }
            }
        }
        this.setState({ query: newQuery, currentPageNumber: pageId });
        this.props.history.push('/' + this.state.icao + '/flights?' + newQuery);

        this.getContentPage('?' + newQuery, this.companyIcaoFilter(this.state.icao));
    }

    fieldClicked = (field) => {
        if (grantPermission('aircraft_show', this.props.auth)) {
            this.props.history.push('/' + this.state.icao + '/flights/' + field[0]);
        }
        return true;
    };

    render() {
        let fields = [];
        let transportUnits = [...this.state.results];
        transportUnits.forEach(result => {

            let fromAirport = (<div className="center">-</div>);
            let statusColor = this.props.statusHandler.getFullStatus(result.transportUnitStatus).color;

            if (null != result.fromAirport) {
                fromAirport = (
                    <span>
                        <span style={divStyleCity}>
                            {result.fromAirport.city}
                        </span>
                        <small> ({result.fromAirport.icao})</small>
                    </span>
                )
            }

            let toCity = (<div className="center">-</div>);

            if (null != result.toAirport) {
                toCity = (
                    <span>
                        <span style={divStyleCity}>
                            {result.toAirport.city}
                        </span>
                        <small> ({result.toAirport.icao})</small>
                    </span>
                )
            }

            let phase = 'LANDED';
            let statusFlight = "", phaseIcon;

            if (result.flightPhases.length > 0) {
                phase = result.flightPhases[result.flightPhases.length - 1].flightPhaseType.type;
            }

            if (phase !== "not tracked") {
                phaseIcon = (<span className={phase} style={{ fontSize: 20, marginLeft: '3px' }}></span>)
                statusFlight = (<div style={{ marginLeft: '10px' }}>{phaseIcon}{" "}{phase.toUpperCase()}</div>)
            }

            let tabData = [
                result.uuid,
                result.transportUnit.operatorCompany.name,
                result.transportUnit.registration,
                result.flightNumber,
                fromAirport,
                toCity,
                result.departureAt,
                statusFlight,
                <span
                    className={"label label-" + statusColor}>{result.transportUnitStatus ? result.transportUnitStatus : "Unknown"}</span>
            ];

            if (phase === "landed" && grantPermission('aircraft_flights_closed', this.props.auth)) {
                fields.push(tabData);
            }

            if (phase !== "landed") {
                fields.push(tabData);
            }
        });

        let flightsTable = (
            <div className="box-body">
                <Table
                    tableHeadClicked={field => this.tableHeadClickedHandler(field)}
                    transportUnitClicked={field => this.fieldClicked(field)}
                    fieldsName={this.state.fieldsName}
                    fields={fields}
                    orderBy={this.state.orderBy}
                    currentFieldOrder={this.state.currentFieldOrder}
                    numberOfResult={this.state.results.length}
                    clickable={grantPermission('aircraft_show', this.props.auth)}
                />
                <TableFooter
                    transportUnits={this.state.transportUnits}
                    navigation={this.state.navigation}
                    location={this.props.location}
                    currentPage={this.state.currentPageNumber}
                    numberOfResult={this.state.results.length}
                    navigationClicked={(pageId) => this.navigationClickedHandler(pageId)}
                />
            </div>
        );

        if (this.state.loading) {
            flightsTable = (
                <div className="box-body">
                    <Spinner />
                </div>
            );
        }

        let content = (
            <div className="box">
                <div style={{ marginBottom: 0, padding: '0 10px', boxShadow: 'none' }} className="row box-default col-12">
                    <SearchInput
                        onSearchChange={this.onSearchChangeHandler}
                        searchTerm={this.state.searchFieldRegistration}
                        placeholder='Registration' />
                    <SearchInput
                        onSearchChange={this.onSearchChangeHandler}
                        searchTerm={this.state.searchFieldFlightNumber}
                        placeholder='Flight Number' />
                    <SelectInput
                        id='status'
                        onSelectChange={this.onSelectChangeHandler}
                        selectTerm={this.state.selectFieldStatus}
                        default='System Status'
                        options={[
                            { status: 'maintenance', displaying: 'Maintenance' },
                            { status: 'error', displaying: 'Error' },
                            { status: 'warning', displaying: 'Warning' },
                            { status: 'ok', displaying: 'OK' },
                            { status: 'not_activated', displaying: 'Not Activated Yet' },
                        ]} />
                    <DateInput
                        onSearchChange={this.onSearchChangeHandler}
                        searchTerm={this.state.searchFieldDepartureAt}
                        placeholder='Departure At' />
                </div>
                {flightsTable}
            </div>
        );

        if (this.state.error.status) {
            content = <MessageInfo refreshHandler={this.refreshHandler} message={this.state.error.message} />
        }

        return (
            <Aux>
                <ContentHeader
                    refreshClicked={this.refreshHandler}
                    nameHeader='Flights'
                    smallHeader='List' />
                <section className="content">
                    <div className="row">
                        <div className="col-xs-12">
                            {content}
                        </div>
                    </div>
                </section>
            </Aux>
        );
    }
}

export default withRouter(Flights);
