import React, {Component} from 'react'

class tableBody extends Component {

    render () {
        let id = 0;
        let contentBody = this.props.fields.map(field => (
                <tr
                    key={field}>
                    {field.map(element => {
                        let elementOuput = null;
                        if (field[0] !== element) {
                            elementOuput = <td key={id++}>{element}</td>
                        }
                        return elementOuput;
                    })}
                {this.props.clickable ? (
                    <td>
                        <button onClick={() => this.props.transportUnitClicked(field)} className={"btn btn-sm btn-default view_link " + (this.props.clickable ? "" : "disabled")} title="Show" aria-disabled="true">
                            <i className="fa fa-eye" aria-hidden="true" /> Show
                        </button>
                    </td>
                ) : null}
                </tr>
            )
        );

        return (
            <tbody>
            {contentBody}
            </tbody>
        );
    }
}

export default tableBody;
