export const GetOrder = (order, queries, property) => {
    for (let param of queries) {
        if (param[0] === 'order[' + property + ']') {
            if (order === 'DESC') {
                order = 'ASC';
            } else {
                order = 'DESC';
            }
        } else {
            if (order === 'DESC') {
                order = 'ASC';
            } else {
                order = 'DESC';
            }
        }
    }
    return order;
};

