const ADMIN_ROLE = "ROLE_ADMIN"
const DASHBAORD_ROLE = "PAGE_DASHBOARD"
const AIRCRAFT_SHOW_ROLE = "PAGE_AIRCRAFT_SHOW"
const AIRCRAFT_LIST_ROLE = "PAGE_AIRCRAFT_LIST"
const HEARTBEAT_ROLE = "PAGE_HEARTBEAT_SHOW"
const MEDIA_SYNC_ROLE = "PAGE_MEDIA_SYNC_SHOW"
const UGOLOG_ROLE = "PAGE_UGOLOG_SHOW"
const RMC_ROLE = "PAGE_RMC_SHOW"
const FLIGHT_ROLE = "PAGE_FLIGHT_SHOW"
const PAGE_FLIGHT_OVERRIDE_STATUS = "PAGE_FLIGHT_OVERRIDE_STATUS"
const PAGE_FLIGHT_INTERNAL_COMMENT = 'PAGE_FLIGHT_INTERNAL_COMMENT'
const AIRMAP_ROLE = "PAGE_AIRMAP_SHOW"
const SLIDESHOW_ROLE = "PAGE_SLIDESHOW_SHOW"
const AIRCRAFT_ALERTS_ACK_ROLE = "PAGE_AIRCRAFT_ALERTS_ACK"
const AIRCRAFT_ERP_BUTTON = "PAGE_AIRCRAFT_ERP_BUTTON"
const AIRCRAFT_FLIGHTRADAR_BUTTON = "PAGE_AIRCRAFT_FLIGHTRADAR_BUTTON"
const AIRCRAFT_ALERTS_CURRENT = "PAGE_AIRCRAFT_ALERTS_CURRENT"
const AIRCRAFT_ALERTS_CLOSED = "PAGE_AIRCRAFT_ALERTS_CLOSED"
const AIRCRAFT_FLIGHTS_CLOSED = "PAGE_AIRCRAFT_FLIGHTS_CLOSED"
const AIRCRAFT_ALERTS_SETTINGS_VIEW = "PAGE_AIRCRAFT_ALERTS_SETTINGS_VIEW"
const UGOLOG_DOWNLOAD = "PAGE_UGOLOG_DOWNLOAD"
const COMPANY_DISPLAY = "DI"
const CONFIGURATION_DEPLOYER = "PAGE_CONFIGURATION_DEPLOYER"
const CONFIGURATION_DEPLOYER_HISTORY = "PAGE_AIRCRAFT_DEPLOYER_HISTORY"
const CONFIGURATION_DEPLOYER_FORCE_DEPLOY = "PAGE_AIRCRAFT_DEPLOYER_FORCE"
const ROLE_PRIVATE_KB = "ROLE_PRIVATE_KB"
const ROLE_PUBLIC_KB = "ROLE_PUBLIC_KB"
const SELF_CONFIGURATION = "PAGE_AUTO_CONFIGURATION"

class ListRole {
    getRole = (role) => {
        switch (role) {
            case 'admin':
                return {
                    role: ADMIN_ROLE,
                }
            case 'dashboard':
                return {
                    role: DASHBAORD_ROLE,
                }
            case 'aircraft_show':
                return {
                    role: AIRCRAFT_SHOW_ROLE,
                }
            case 'aircraft_list':
                return {
                    role: AIRCRAFT_LIST_ROLE,
                }
            case 'heartbeat':
                return {
                    role: HEARTBEAT_ROLE,
                }
            case 'media_sync':
                return {
                    role: MEDIA_SYNC_ROLE,
                }
            case 'ugo_log':
                return {
                    role: UGOLOG_ROLE,
                }
            case 'rmc':
                return {
                    role: RMC_ROLE,
                }
            case 'flight':
                return {
                    role: FLIGHT_ROLE,
                }
            case 'air_map':
                return {
                    role: AIRMAP_ROLE,
                }
            case 'slideshow':
                return {
                    role: SLIDESHOW_ROLE,
                }
            case 'aircraft_alerts_ack':
                return {
                    role: AIRCRAFT_ALERTS_ACK_ROLE,
                }
            case 'aircraft_erp_button':
                return {
                    role: AIRCRAFT_ERP_BUTTON,
                }
            case 'aircraft_flightradar_button':
                return {
                    role: AIRCRAFT_FLIGHTRADAR_BUTTON,
                }
            case 'aircraft_alerts_current':
                return {
                    role: AIRCRAFT_ALERTS_CURRENT,
                }
            case 'aircraft_alerts_closed':
                return {
                    role: AIRCRAFT_ALERTS_CLOSED,
                }
            case 'aircraft_alerts_settings_view':
                return {
                    role: AIRCRAFT_ALERTS_SETTINGS_VIEW,
                }
            case 'aircraft_flights_closed':
                return {
                    role: AIRCRAFT_FLIGHTS_CLOSED,
                }
            case 'ugo_log_download':
                return {
                    role: UGOLOG_DOWNLOAD,
                }
            case 'page_flight_override_status':
                return {
                    role: PAGE_FLIGHT_OVERRIDE_STATUS,
                }
            case 'page_flight_internal_comment':
                return {
                    role: PAGE_FLIGHT_INTERNAL_COMMENT,
                }
            case 'company_display':
                return {
                    role: COMPANY_DISPLAY,
                }
            case 'configuration_deployer':
                return {
                    role: CONFIGURATION_DEPLOYER,
                }
            case 'configuration_deployer_history':
                return {
                    role: CONFIGURATION_DEPLOYER_HISTORY,
                }
            case 'configuration_deployer_force_deploy':
                return {
                    role: CONFIGURATION_DEPLOYER_FORCE_DEPLOY,
                }
            case 'self_configuration':
                return {
                    role: SELF_CONFIGURATION,
                }
            case 'role_public_kb':
                return {
                    role: ROLE_PUBLIC_KB,
                }
            case 'role_private_kb':
                return {
                    role: ROLE_PRIVATE_KB,
                }
            default: return;
        }
    }
}

export default ListRole
