import React from 'react';
import Th from '../Th/Th';

const tableHead = (props) => {
    const fieldsName = props.fieldsName.map(field => {
        let classStyle = 'sorting';
        if (field === props.currentFieldOrder) {
            classStyle = 'oderBy' + props.orderBy;
        }
        return (
            <Th
                tableHeadClicked={field => props.tableHeadClicked(field)}
                key={field} classStyle={classStyle}
                field={field}/>
        )
    });

    return (
        <thead>
            <tr role='row'>
                {fieldsName}
                {props.clickable ? (<th>Action</th>) : null}
            </tr>
        </thead>
    )
};

export default tableHead;
