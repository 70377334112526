import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './datepicker.css';

const dateInput = (props) => {
    let value = Date.parse(props.searchTerm);
    return (
        <div className="col-xs-6 col-sm-6 col-md-3 col-mg-2" style={{marginTop: 5}}>
                <DatePicker
                    placeholderText={props.placeholder}
                    fixedHeight
                    dateFormat="yyyy-MM-dd"
                    isClearable
                    placeholder='Enter date'
                    selected={value}
                    onChange={(date) => props.onSearchChange(date, props.placeholder)}
                />
        </div>
    );
};

export default dateInput;
