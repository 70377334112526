import React from 'react';

const searchInput = (props) => {
    return (
        <div className="col-xs-6 col-sm-6 col-md-3 col-mg-2" style={{marginTop: 5}}>
            <div className="has-feedback">
                <input
                    style={{width:'100%', height:40, fontSize: 13}}
                    onChange={(event) => props.onSearchChange(event, props.placeholder)}
                    type="text"
                    id={props.placeholder}
                    value={props.searchTerm}
                    className="form-control input-sm"
                    placeholder={props.placeholder + '...'} />
                <span className="glyphicon glyphicon-search form-control-feedback"/>
            </div>
        </div>
    );
};

export default searchInput;
