import React, {Component} from 'react'
import {Map, TileLayer, Marker, Popup, Polyline, LayersControl} from 'react-leaflet'
import L from 'leaflet'
import './assets/Map.css'
import FlightStatusBox from "./AircraftFlightBox/FlightStatusBox";
import Spinner from "../../../components/UI/Spinner/Spinner";
const { BaseLayer } = LayersControl;

class aircraftFlightDetail extends Component {
    constructor (props) {
        super(props);
        this.auth = this.props.auth;
    }

    state = {
        zoom: 3,
        data: {},
        loading: true,
        orderBy: 'ASC',
        error: {
            status: false,
            message: ''
        },
    };
    componentDidMount() {
        if (this.props.transportUnit && this.props.transportUnit.lastDataFlights) {
            let search = "flight_positions?pagination=false&flight.uuid=" + this.props.transportUnit.lastDataFlights.uuid;
            this.getContent(search)
        }
    }

    getContent = (search) => {
        this.auth.fetch(search)
            .then(res => {
                if (res.code === 401) {
                    this.props.history.push({
                        pathname: '/login',
                        state: {previousUrl: this.props.location.pathname}
                    });
                }
                this.setState({ data: res, loading: false });
            })
            .catch(e => {
                this.setState({
                    loading: false,
                    error: {
                        status: true,
                        message: e.toString()
                    }
                });
            });
    };

    render() {
        let LastPositionAircraft = [this.props.transportUnit.lastLatitude, this.props.transportUnit.lastLongitude];
        let currentMap = (
            <div className="col-md-12">
                <h3>Unknown position</h3>
                <p>No flight or position has been received from the flight tracking provider since the option has been activated here. Position should be saved during the next flight of this aircraft.</p>
            </div>
        );

        /**
         * @author : Antoine .C
         * Marker color : https://github.com/pointhi/leaflet-color-markers
         */
        let greenIcon = new L.Icon({
            iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-green.png',
            shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
        });
        let greyIcon = new L.Icon({
            iconUrl: 'https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-grey.png',
            shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
            shadowSize: [41, 41]
        });



        if (this.props.transportUnit.lastDataFlights && this.props.transportUnit.lastDataFlights.fromAirport && !this.props.transportUnit.lastDataFlights.arrivalAt) {
            let planeHtml = "<div class='icon-plane' style='transform: rotate("+this.props.transportUnit.lastDataFlights.lastDataPositions.heading+"deg)'></div>";
            let faPlane = L.divIcon({className: '', html: planeHtml, iconAnchor: [12,15]});

            let positionAirportFrom = [this.props.transportUnit.lastDataFlights.fromAirport.latitude, this.props.transportUnit.lastDataFlights.fromAirport.longitude];
            let positionAirportTo = null;

            if (this.props.transportUnit.lastDataFlights.toAirport) {
                positionAirportTo = [this.props.transportUnit.lastDataFlights.toAirport.latitude, this.props.transportUnit.lastDataFlights.toAirport.longitude];
            }


            let airports =
                <div className={"row"}>
                    <div className={"col-md-12"}>
                        <div className={"pull-left"}>
                            {this.props.transportUnit.lastDataFlights.fromAirport.icao} ({this.props.transportUnit.lastDataFlights.fromAirport.city})
                        </div>
                        <div className={"pull-right"}>
                            {positionAirportTo ? this.props.transportUnit.lastDataFlights.toAirport.icao :'Unknown destination'} {positionAirportTo ? "(" + this.props.transportUnit.lastDataFlights.toAirport.city + ")" : '' }
                        </div>
                    </div>
                </div>;

            if (this.state.loading === false) {
                let flightPositions = this.state.data['hydra:member'];
                let polyline = [];
                if(flightPositions){
                    polyline = flightPositions.map((flightPosition, key) => {
                        return [flightPosition.latitude, flightPosition.longitude];
                    });
                }
                polyline.push(positionAirportFrom);

                let bound, markerAirportTo = null;
                if (positionAirportTo) {
                    bound = new L.featureGroup([L.marker(positionAirportFrom), L.marker(LastPositionAircraft), L.marker(positionAirportTo)]).getBounds().pad(0.5);

                    markerAirportTo = (
                        <Marker position={positionAirportTo} icon={greenIcon} zIndexOffset={5}>
                            <Popup>
                                <div className="text-center">
                                    {this.props.transportUnit.lastDataFlights.toAirport.city} ({this.props.transportUnit.lastDataFlights.toAirport.icao})
                                </div>
                            </Popup>
                        </Marker>
                    );

                } else {
                    bound = new L.featureGroup([L.marker(positionAirportFrom), L.marker(LastPositionAircraft)]).getBounds().pad(0.5);
                    markerAirportTo = ""
                }

                currentMap =
                <div>
                    <FlightStatusBox
                        icon={this.props.transportUnit.lastDataFlights.phase && null !== this.props.transportUnit.lastDataFlights.phase ? this.props.transportUnit.lastDataFlights.phase + "-flightBox-black" : "fa fa-plane"}
                        title={"Flight " + this.props.transportUnit.lastDataFlights.phase && null !== this.props.transportUnit.lastDataFlights.phase ? this.props.transportUnit.lastDataFlights.phase : ""}
                        airport={this.props.transportUnit.lastDataFlights.flightNumber}
                        descriptionLeft={airports}
                        descriptionRight={""}
                        color={"blue"}
                        depatureAt={this.props.transportUnit.lastDataFlights.departureAt}
                        uuid={this.props.transportUnit.lastDataFlights.uuid}
                        auth={this.props.auth}
                        icao={this.props.icao}
                    />

                    <div className="col-md-7">
                        <div className="map-container">
                            <Map
                                center={LastPositionAircraft}
                                zoom={this.state.zoom}
                                bounds={bound}
                                attributionControl={false}
                                maxZoom="12"
                            >
                                <LayersControl>
                                    <BaseLayer checked name="UGO UHD map">
                                        <TileLayer
                                            url="https://maps-commercial.ife.ugo.aero/tiles/{z}/{x}/{y}.jpg"
                                            tms="true"
                                        />
                                    </BaseLayer>
                                    <BaseLayer checked name="UGO Kids map">
                                        <TileLayer
                                            url="https://gis.ife.ugo.aero//medias/tiles/default/{z}/{x}/{y}.jpg"
                                        />
                                    </BaseLayer>
                                    <BaseLayer checked name="OpenStreetMap">
                                        <TileLayer
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        />
                                    </BaseLayer>
                                </LayersControl>
                                <Polyline color="green" positions={polyline}/>
                                <Marker position={positionAirportFrom} icon={greyIcon} zIndexOffset={5}>
                                    <Popup>
                                        <div className="text-center">
                                            {this.props.transportUnit.lastDataFlights.fromAirport.city} ({this.props.transportUnit.lastDataFlights.fromAirport.icao})
                                        </div>
                                    </Popup>
                                </Marker>
                                <Marker position={LastPositionAircraft} icon={faPlane} zIndexOffset={10}>
                                    <Popup>
                                        <div className="text-center">
                                            lat/long <br/>
                                            {this.props.transportUnit.lastLatitude + '°' || '-'}/
                                            {this.props.transportUnit.lastLongitude + '°' || '-'}
                                        </div>
                                    </Popup>
                                </Marker>
                                {markerAirportTo}
                            </Map>
                        </div>
                        <div className={"box-footer no-border"}>
                            <div className="row">
                                <div className="col-md-4 text-center metrics-separator">
                                    <i title="Logs" className="fa fa-arrows-v"
                                       style={{
                                           fontSize: 17,
                                           color: '#3c8dbc'
                                       }}/> {this.props.transportUnit.lastDataFlights.lastDataPositions.altitudeInFeet.toFixed()} ft
                                </div>
                                <div className="col-md-4 text-center metrics-separator">
                                    <i title="Logs" className="fa fa-clock-o"
                                       style={{
                                           fontSize: 17,
                                           color: '#3c8dbc'
                                       }}/> {this.props.transportUnit.lastDataFlights.lastDataPositions.speedKnots.toFixed()} kts
                                </div>
                                <div className="col-md-4 text-center">
                                    <i title="Logs" className="fa fa-arrows"
                                       style={{
                                           fontSize: 17,
                                           color: '#3c8dbc'
                                       }}/> {this.props.transportUnit.lastDataFlights.lastDataPositions.heading}°
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                ;
            } else {
                currentMap = (
                    <div className="col-md-12">
                        <h3> <Spinner/></h3>
                    </div>
                );
            }

        } else if (this.props.transportUnit.lastDataFlights && this.props.transportUnit.lastLatitude != null && this.props.transportUnit.lastLongitude != null) {

            let airport = "-";

            if (this.props.transportUnit.lastDataFlights && this.props.transportUnit.lastDataFlights.toAirport) {
                airport = this.props.transportUnit.lastDataFlights.toAirport.icao + " - " + this.props.transportUnit.lastDataFlights.toAirport.city
            } else if (this.props.transportUnit.lastDataFlights && this.props.transportUnit.lastDataFlights.fromAirport) {
                airport = this.props.transportUnit.lastDataFlights.fromAirport.icao + " - " + this.props.transportUnit.lastDataFlights.fromAirport.city
            }

            if (this.props.transportUnit.lastLatitude && this.props.transportUnit.lastLongitude) {
                currentMap =
                    <div>
                        <FlightStatusBox
                            icon={"fa fa-map-marker"}
                            title={"Last known position"}
                            airport={airport}
                            descriptionLeft={this.props.transportUnit.lastDataFlights.arrivalAt ? "Last seen: " + this.props.transportUnit.lastDataFlights.arrivalAt : " "}
                            descriptionRight={" "}
                            color={"gray"}
                        />
                        <div className="col-md-7">
                            <div className="map-container">
                                <Map
                                    center={LastPositionAircraft}
                                    zoom={this.state.zoom}
                                    attributionControl={false}
                                    maxZoom="12"
                                >
                                    <LayersControl>
                                        <BaseLayer checked name="UGO UHD map">
                                            <TileLayer
                                                url="https://maps-commercial.ife.ugo.aero/tiles/{z}/{x}/{y}.jpg"
                                                tms="true"
                                            />
                                        </BaseLayer>
                                        <BaseLayer checked name="UGO Kids map">
                                            <TileLayer
                                                url="https://gis.ife.ugo.aero//medias/tiles/default/{z}/{x}/{y}.jpg"
                                            />
                                        </BaseLayer>
                                        <BaseLayer checked name="OpenStreetMap">
                                            <TileLayer
                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            />
                                        </BaseLayer>
                                    </LayersControl>
                                    <Marker position={LastPositionAircraft}>
                                        <Popup>
                                            <div className="text-center">
                                                lat/long <br/>
                                                {this.props.transportUnit.lastLatitude + '°' || '-'}/
                                                {this.props.transportUnit.lastLongitude + '°' || '-'}
                                            </div>
                                        </Popup>
                                    </Marker>
                                </Map>
                            </div>
                        </div>
                    </div>
                ;
            }
        }
        return (
            <div>
                {currentMap}
            </div>);

    }
}

export default aircraftFlightDetail;
