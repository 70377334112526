import React from 'react';
import {Link} from 'react-router-dom';

const paginationElement = (props) => {
    return (
        <li id={props.id} onClick={() => props.navigationClicked(props.currentPage)} className={props.isActive ? 'active' : null}>
            <Link to={'?' + props.pageParameterName + '=' +  props.currentPage + props.newQueryWithoutPagination + props.anchor}>{props.currentPage}</Link>
        </li>
    );
};

export default paginationElement;
