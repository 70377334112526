import {withRouter} from 'react-router-dom';

import AbstractByAircraft from './AbstractByAircraft';

class HeartBeatsByAircraft extends AbstractByAircraft {
    constructor(props) {
        super(props);
        this.specificUri = 'aircraft_heartbeats';
        this.state = {
            resList: [],
            loading: true,
            currentPageNumber: 1,
            orderBy: 'ASC',
            currentFieldOrder: null,
            navigation: {
                totalItems: 0,
                first: '',
                next: '',
                last: '',
                totalPage: 0
            },
            error: {
                status: false,
                message: ''
            },
            anchor: '#heartbeat',
            fieldsName: [
                'Airport',
                'Route',
                'Status',
                'Media PartNumber',
                'Lxc UGO PartNumber',
                'Configuration Date',
                'Created At',
            ]
        };
    }

    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search)
        let currentFieldOrder;
        let orderBy = 'ASC'

        if (query.has('order[route]')) {
            currentFieldOrder = 'Route'
            orderBy = query.get('order[route]')
        }
        if (query.has('order[airport.name]')) {
            currentFieldOrder = 'Airport'
            orderBy = query.get('order[airport.name]')
        }
        if (query.has('order[serverStatus]')) {
            currentFieldOrder = 'Status'
            orderBy = query.get('order[serverStatus]')
        }
        if (query.has('order[mediaPartNumber]')) {
            currentFieldOrder = 'Media PartNumber'
            orderBy = query.get('order[mediaPartNumber]')
        }
        if (query.has('order[lxcUgoPartNumber]')) {
            currentFieldOrder = 'Lxc UGO PartNumber'
            orderBy = query.get('order[lxcUgoPartNumber]')
        }
        if (query.has('order[configurationDate]')) {
            currentFieldOrder = 'Configuration Date'
            orderBy = query.get('order[configurationDate]')
        }
        if (query.has('order[createdAt]')) {
            currentFieldOrder = 'Created At'
            orderBy = query.get('order[createdAt]')
        }
        this.setState({
            currentFieldOrder,
            orderBy
        })

        this.getContentPage('?transportUnit.uuid=' + this.props.transportUnitUuid);
    }

    getProperty = (field) => {
        let property;

        switch (field) {
            case 'Airport':
                property = 'airport.name';
                break;
            case 'Route':
                property = 'route';
                break;
            case 'Status':
                property = 'serverStatus';
                break;
            case 'Media PartNumber':
                property = 'mediaPartNumber';
                break;
            case 'Lxc UGO PartNumber':
                property = 'lxcUgoPartNumber';
                break;
            case 'Configuration Date':
                property = 'configurationDate';
                break;
            case 'Created At':
                property = 'createdAt';
                break;
            default:
                property = '';
        }

        return property;
    };

    fieldClicked = (fields) => {
        if (this.props.clickable) {
            const heartBeatUuid = fields[0]
            this.props.history.push(`/${this.props.icao}/heartbeat/${heartBeatUuid}`)
        }
        return true
    }

    getFields = () => {
        let fields = [];
        const resList = [...this.state.resList];

        resList.forEach(res => {
            fields.push([
                res.uuid,
                res.airport ? res.airport.name : null,
                res.route,
                res.serverStatus,
                res.mediaPartNumber,
                res.lxcUgoPartNumber,
                res.configurationDate,
                res.createdAt,
            ])
        });

        return fields;
    };
}

export default withRouter(HeartBeatsByAircraft);
