import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';

import PaginationElement from './PaginationElement';
import Aux from '../../../hoc/Aux/Aux';

class Pagination extends Component {

    getStartPage = () => {
        let startPage = 1;
        if (this.props.currentPage > 2 && (this.props.navigation.totalPage > 4)) {
            startPage = this.props.currentPage - 2;

            if ((this.props.navigation.totalPage - this.props.currentPage < 3) && (this.props.navigation.totalPage > 4)) {
                startPage = (this.props.currentPage + 1) - (5 - (this.props.navigation.totalPage - this.props.currentPage));
            }
        }

        return startPage;
    };

    getFinishPage = () => {
        const startPage = this.getStartPage();
        let finishPage = startPage + 4;

        if (startPage + 4 > this.props.navigation.totalPage) {
            finishPage = this.props.navigation.totalPage;
        }

        return finishPage;
    };

    showLastPagePrefixedByThreePoints = () => {
        if (this.props.navigation.totalPage >= 10 && (this.props.navigation.totalPage - this.props.currentPage >= 3)) {
            return true;
        }

        return false;
    };

    render() {
        const { currentPage, location, navigation, navigationClicked } = this.props;
        const query = new URLSearchParams(location.search);
        const totalPage = navigation.totalPage;
        const startPage = this.getStartPage();
        const finishPage = this.getFinishPage();
        this.showLastPagePrefixedByThreePoints();

        let pageParameterName = 'page'
        if (this.props.pageParameter !== undefined && typeof this.props.pageParameter !== 'undefined') {
            pageParameterName = this.props.pageParameter;
        }

        let anchor = ''
        if (this.props.anchor !== undefined) {
            anchor = this.props.anchor;
        }


        let newQueryWithoutPagination = '';
        for (let param of query.entries()) {
            if (param[0] === pageParameterName) {
                continue;
            }

            if (param[1]) {
                newQueryWithoutPagination += '&' + param[0] + '=' + param[1];
            } else {
                newQueryWithoutPagination += '&' + param[0];
            }
            newQueryWithoutPagination += this.props.hash
        }

        let pagination = [];

        for (let i = startPage; i <= finishPage; i++) {
            if (totalPage === 1) {
                return;
            }
            let isActive = false;

            if (this.props.currentPage === i) {
                isActive = true;
            }

            pagination.push(
                <PaginationElement
                    key={i}
                    id={'page-' + i}
                    currentPage={i}
                    pageParameterName={pageParameterName}
                    newQueryWithoutPagination={newQueryWithoutPagination}
                    isActive={isActive}
                    navigationClicked={navigationClicked}
                    anchor={anchor}
                />
            );
        }

        let previousPage = (
            <li id='previous'
                className='previous'
                onClick={(currentPage === 1) ? null : () => navigationClicked(currentPage - 1)}>
                <Link to={`?${pageParameterName}=${(currentPage - 1) + newQueryWithoutPagination + anchor}`} aria-label="Previoudds">Previous</Link>
            </li>
        );

        let firstPage = (
            <li id='first'
                className='first'
                onClick={() => navigationClicked(1)}>
                <Link to={`?${pageParameterName}=${1 + newQueryWithoutPagination + anchor}`} aria-label="First">First</Link>
            </li>
        );

        if (currentPage === 1) {
            previousPage = (
                <li id='previous' className='previous disabled'>
                    <a href='#0' style={{ color: 'lightgrey' }} aria-label="Previous">Previous</a>
                </li>
            );
            firstPage = (
                <li id='first' className='first disabled'>
                    <a href='#0' style={{ color: 'lightgrey' }} aria-label="First">First</a>
                </li>
            );
        }

        let nextPage = (
            <li
                id='next'
                className={(currentPage === totalPage) ? 'next disabled' : 'next'}
                onClick={() => navigationClicked(currentPage + 1)}>
                <Link to={`?${pageParameterName}=${(currentPage + 1) + newQueryWithoutPagination + anchor}`}>Next</Link>

            </li>
        );

        if (currentPage === totalPage) {
            nextPage = (
                <li id='next' className='next disabled'>
                    <a href='#0' style={{ color: 'lightgrey' }} aria-label="Next">Next</a>
                </li>
            );
        }

        let lastPage = null;

        if (this.showLastPagePrefixedByThreePoints()) {
            lastPage = (
                <Aux>
                    {this.props.navigation.totalPage - this.props.currentPage === 3 ? null : (
                        <li id='more'>
                            <a href='#0' className='desabled'>...</a>
                        </li>
                    )}
                    <li
                        id='last'
                        onClick={() => navigationClicked(navigation.totalPage)}>
                        <Link to={`?${pageParameterName}=${(navigation.totalPage) + newQueryWithoutPagination + anchor}`}>{navigation.totalPage}</Link>
                    </li>
                </Aux>
            );
        }

        return (
            <ul className='pagination'>
                {previousPage}
                {firstPage}
                {pagination}
                {lastPage}
                {nextPage}
            </ul>
        )
    }
}

export default Pagination;

Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    navigation: PropTypes.shape({
        totalPage: PropTypes.number
    }),
    location: PropTypes.object,
    navigationClicked: PropTypes.func
};
