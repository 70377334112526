import React from 'react'
import {withRouter} from 'react-router-dom';

import AbstractByAircraft from './AbstractByAircraft';
import './AlertByAircraft.js'
import ModalAck from '../ModalAck/ModalAck'
import moment from 'moment';

class AlertByAircraft extends AbstractByAircraft {
    constructor(props) {
        super(props);
        this.specificUri = 'transport_unit_alert_histories'
        this.statusHandler = this.props.statusHandler
        this.state = {
            resList: [],
            loading: true,
            currentPageNumber: 1,
            orderBy: 'DESC',
            currentFieldOrder: 'updatedAt',
            navigation: {
                totalItems: 0,
                first: '',
                next: '',
                last: '',
                totalPage: 0
            },
            status: {
                color: 'success',
                icon: 'Closed'
            },
            anchor: '#alerts',
            error: {
                status: false,
                message: ''
            },
            fieldsName: [
            ],
            tableTitle: this.props.tableTitle,
            messageTitle: this.props.messageTitle
        }
    }

    updateHeader = (closedAt = true) => {
        if (false === closedAt) {
            this.setState({
                fieldsName: [
                    'Status',
                    'Opened At',
                    'Reason',
                    'Explanation',
                    'ACK'
                ]
            })
        } else {
            this.setState({
                fieldsName: [
                    'Opened At',
                    'Closed At',
                    'Reason',
                    'Explanation'
                ]
            })
        }
    }

    updateMessageHeader = (query) => {
        this.Auth
            .fetch(this.specificUri + query)
            .then(res => {
                let totalItems = res['hydra:totalItems'] ? res['hydra:totalItems'] : 0;
                if (totalItems > 0) {
                    this.setState({
                        messageTitle: ""
                    })
                }
            })
            .catch(e => {
                console.log("error e", e.toString());
            });
    }

    getColorFromClassName = (str) => {
        return str.substring(str.indexOf('-') + 1)
    }

    fieldClicked = (field) => {
        if (this.props.clickable) {
            const color = this.getColorFromClassName(field[1].props.className)
            const alertId = field[0]

            const popup = (
                <ModalAck
                    getContentPage={(query) => this.getContentPage(query)}
                    closePopup={this.closePopup}
                    alertId={alertId}
                    color={color}
                    transportUnitUuid={this.props.transportUnitUuid}
                />
            )

            this.setState({ popup })
        }
        return true
    }

    closePopup = () => {
        if (this.state.popup === '') {
            return
        }

        this.setState({ popup: '' })
    }

    getProperty = (field) => {
        let property;
        switch (field) {
            case 'Status':
                property = 'alertReason.alertStatus.alertStatusType';
                break;
            case 'Opened At':
                property = 'createdAt';
                break;
            case 'Closed At':
                property = 'closedAt';
                break;
            case 'Reason':
                property = 'alertReason.description';
                break;
            case 'Explanation':
                property = 'explanation';
                break;
            default:
                property = '';
        }

        return property;
    }

    componentDidMount() {
        let currentFieldOrder = this.state.currentFieldOrder;
        let orderBy = 'DESC'
        this.setState({
            currentFieldOrder: currentFieldOrder,
            orderBy: orderBy
        })
        const defaultQuery = 'order[' + currentFieldOrder + ']=' + orderBy;
        let closedAt = this.props.closedAt === false ? false : true;
        const url = `?transportUnit.uuid=${this.props.transportUnitUuid}&${defaultQuery}&exists[closedAt]=${closedAt}`;
        this.updateMessageHeader(url);
        this.getContentPage(url);
        this.updateHeader(closedAt);
    }

    getStateClosedAt() {
        return this.props.closedAt === false ? false : true;
    }

    getList(pageId, property = null, order = 'DESC', currentFilter = null) {
        this.setState({ loading: true });
        let newQuery = '';
        newQuery += 'page=' + pageId;
        if (currentFilter) {
            newQuery += '&' + currentFilter;
        }
        if (property) {
            newQuery += '&order[' + property + ']=' + order;

        } else {
            const query = new URLSearchParams(this.props.location.search);
            for (let param of query.entries()) {
                if (param[0] === 'page') {
                    continue;
                }

                if (param[1]) {
                    newQuery += '&' + param[0] + '=' + param[1];
                } else {
                    newQuery += '&' + param[0];
                }
            }
        }
        
        order = order === 'DESC' ? 'ASC': 'DESC';

        this.setState({ orderBy: order, currentFieldOrder: property, query: newQuery });
        let closedAt = this.props.closedAt === false ? false : true;
        const url = '?transportUnit.uuid=' + this.props.transportUnitUuid + '&' + newQuery + '&exists[closedAt]=' + closedAt;
        this.props.history.push(url);
        this.updateMessageHeader(url);
        this.getContentPage(url);
        this.updateHeader(closedAt);
    }

    getFields = () => {
        let fields = [];
        const resList = [...this.state.resList];

        resList.forEach(res => {
            let ackEndDatetime = moment(res.ackEndDatetime);
            let now = moment();
            let isACK = ackEndDatetime > now;

            const desc = res.alertReason ? res.alertReason.description : '';
            const aStatus = res.alertReason ? res.alertReason.alertStatus.alertStatusType : null;
            const status = this.statusHandler.getStatusWithoutMaintenance(res.closedAt, aStatus);
            const className = 'label label-' + status.color;
            //For error and warning status
            let array = [
                res.id,
                (
                    <span className={className}>
                        {
                            status.color === 'danger' ? 'Error' :
                                status.color === 'warning' ? 'Warning' :
                                    status.color === 'primary' ? 'Maintenance' :
                                        status.color === 'notice' ? 'Notice' : 'Closed'
                        }
                    </span>
                ),
                res.createdAt,
                desc,
                res.explanation,
                isACK && <span style={{ margin: 'auto' }} className="label bg-green"><i className='icon fa fa-check' /></span>
            ]
            // For histories
            if (true === this.getStateClosedAt()) {
                array = [
                    res.id,
                    res.createdAt,
                    res.closedAt,
                    desc,
                    res.explanation
                ]
            }

            fields.push(array);
        });
        return fields;
    }
}

export default withRouter(AlertByAircraft);
