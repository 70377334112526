import React from 'react';

function AircraftSummarySync(parsedObject) {
    const htmlElements = [];
    for (const key in parsedObject) {
        if (parsedObject.hasOwnProperty(key)) {
            const obj = parsedObject[key];
            if (obj.scenario === "merge") {
                htmlElements.push(
                    <tr>
                        <td>
                            <b>
                                <i title="Logs" className="fa fa-cloud-download" style={{fontSize: 17, color: '#3c8dbc'}}/> Last {obj.frequency} sync
                            </b>
                        </td>
                        <td className="pull-right">{obj.finished_at} ({obj.part_number})</td>
                    </tr>
                );
            }
        }
    }

    return htmlElements;
}

export default AircraftSummarySync;
