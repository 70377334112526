export const FormatTime = (timerTime) => {
    if (1 > timerTime) {
        return '-';
    }
    let fulltime;
    let hours = (timerTime / 60);
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.floor(minutes);

    if (0 === rhours) {
        fulltime = rminutes + "min";
        return fulltime;
    }
    fulltime = rhours + 'h' + rminutes + "min";
    return fulltime;
};

