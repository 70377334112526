import React from 'react';

import TableHead from './TableHead/TableHead';
import TableBody from './TableBody';
import parse from "html-react-parser";
import BoxSolid from "../BoxSolid/BoxSolid";
import Aux from "../../../hoc/Aux/Aux";

const table = (props) => {
    const tableTitle = props.tableTitle  ? (
        <h3>{props.tableTitle}</h3>
    ) : ""
    const messageTitle = props.messageTitle ? (
        <span>{parse(props.messageTitle)}</span>
    ) : ""

const tableHead = props.numberOfResult > 0 ? (
                <table className='table table-bordered table-hover table-striped dataTable'>
                    <TableHead
                        tableHeadClicked={field => props.tableHeadClicked(field)}
                        orderBy={props.orderBy}
                        currentFieldOrder={props.currentFieldOrder}
                        fieldsName={props.fieldsName}
                        clickable={props.clickable}
                    />
                    <TableBody
                        transportUnitClicked={field => props.transportUnitClicked(field)}
                        fields={props.fields}
                        clickable={props.clickable}
                    />
                </table>
        ) : ""

    return (
        <Aux>
            {tableTitle}
            {
                props.showBoxSolid ? (
                    <BoxSolid
                        color={props.boxColor}
                        icon={props.boxIcon}
                        title={props.boxMessageTitle}
                        content={props.boxMessageContent} />
                ) : ""
            }
            {messageTitle}
            <div className="table-responsive">
                {tableHead}
            </div>
        </Aux>

    )
};

export default table;
