import React from 'react';

import SidebarMenu from './SidebarItem/SidebarMenu/SidebarMenu';

const sidebarItems = ({auth}) => (
    <div className='sidebar'>
        {/*<UserPanel/>*/}
        <SidebarMenu auth={auth} />
    </div>
);

export default sidebarItems;
