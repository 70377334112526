import {withRouter} from 'react-router-dom';
import AbstractByAircraft from './AbstractByAircraft';
import React from "react";

class ConfigDeployerBatchByAircraft extends AbstractByAircraft {

    constructor(props) {
        super(props);
        this.specificUri = 'auto_remote_deployer_batches';
        this.state = {
            resList: [],
            loading: true,
            currentPageNumber: 1,
            orderBy: 'ASC',
            currentFieldOrder: null,
            navigation: {
                totalItems: 0,
                first: '',
                next: '',
                last: '',
                totalPage: 0
            },
            error: {
                status: false,
                message: ''
            },
            anchor: '#config_deployer',
            fieldsName: [
                'Reference (hash)',
                'Branch',
                'Started At',
                'Finished At',
                'Status',
                'Link to Gitlab',
                'Link to RMC session'
            ],
            tableTitle: this.props.tableTitle,
            messageTitle: this.props.messageTitle
        }
    }

    getProperty = (field) => {
        let property;

        switch (field) {
            case 'Reference (hash)':
                property = 'autoRemoteDeployerHash.commitHash';
                break;
            case 'Branch':
                property = 'autoRemoteDeployerHash.branch';
                break;
            case 'Finished At':
                property = 'endAt';
                break;
            case 'Started At':
                property = 'startAt';
                break;
            case 'Status':
                property = 'status';
                break;
            case 'Link to Gitlab':
                property = 'pipelineLink';
                break;
            case 'Link to RMC session':
                property = 'remoteConnexion';
                break;
            default:
                property = '';
        }

        return property;
    }

    fieldClicked = (fields) => {
        if (this.props.clickable) {
            const configDeployerBatchUuid = fields[0];
            this.props.history.push(`/${this.props.icao}/config_deployers_batch/${configDeployerBatchUuid}`);
        }
        return true;
    }

    getFields = () => {
        let fields = [];
        const resList = [...this.state.resList];

        resList.forEach(res => {
            let hash = res.autoRemoteDeployerHash ? res.autoRemoteDeployerHash.commitHash.substring(0, 8) : '';
            let linkToGitlab = res.pipelineLink ? <a className="btn btn-sm btn-default view_link" href={res.pipelineLink} target="_blank" rel="noopener noreferrer">Gitlab pipeline</a> : '';
            let status = <span className='label label-default'>{res.status}</span>

            if (res.status === 'todo') {
                status = <span className='label label-notice'>{res.status}</span>
            } else if (res.status === 'in_progress') {
                status = <span className='label label-info'>{res.status}</span>
            } else if (res.status === 'done') {
                status = <span className='label label-success'>{res.status}</span>
            } else if (res.status === 'error') {
                status = <span className='label label-danger'>{res.status}</span>
            } else if (res.status === 'canceled') {
                status = <span className='label label-default'>{res.status}</span>
            }

            let linkToRMC = '';
            if (res.remoteConnexion && res.remoteConnexion.uuid) {
                linkToRMC = <a className="btn btn-sm btn-default view_link" href={`/${this.props.icao}/remote-connection/${res.remoteConnexion.uuid}`} rel="noopener noreferrer">RMC session</a>
            }

            fields.push([
                res.uuid,
                hash,
                res.autoRemoteDeployerHash ? res.autoRemoteDeployerHash.branch : '',
                res.startAt ? res.startAt : '',
                res.endAt ? res.endAt : '',
                status,
                linkToGitlab,
                linkToRMC
            ])
        });

        return fields;
    };
}

export default withRouter(ConfigDeployerBatchByAircraft);
