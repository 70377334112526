import React, {Component} from 'react';
import SettingsDetail from './SettingsDetail';
import {grantPermission} from "../../../../hoc/RoleService/RoleService";
import "./settings.css"

class Settings extends Component {
    state = {isCollapse: false}

    collapse = () => {
        const isCollapse = ! this.state.isCollapse
        this.setState({isCollapse})
    }

    render() {
        let settingsDetail = this.state.isCollapse ? (
            <div className="box-body">
                <SettingsDetail
                    transportUnit={this.props.transportUnit}
                    type={this.props.type}
                />
            </div>
        ) : ""

        return (
            <div className="col-md-12" style={{marginTop:10}}>
                {grantPermission('aircraft_alerts_settings_view', this.props.auth) ?
                <div className="box box-primary box-solid">

                    <div className="box-header clickable" onClick={this.collapse}>
                        <h6 className="box-title box-settings-title" ><i className="fa fa-cog"/> View alert settings <small className="littleTitle">(configured in ERP)</small></h6>
                    </div>
                    {settingsDetail}
                </div>
                : "" }
            </div>
        )
    }
}
export default Settings
