const DEACTIVATED_COLOR = 'secondary'
const DEACTIVATED_ICON = 'off'

const DANGER_COLOR = 'danger'
const WARNING_COLOR = 'warning'
const NOTICE_COLOR = 'notice'
const MAINTENANCE_COLOR = 'info'
const SUCCESS_COLOR = 'success'

const MAINTENANCE_ICON = 'wrench'
const DANGER_ICON = 'remove'
const WARNING_ICON = 'alert'
const NOTICE_ICON  = 'ok'
const SUCCESS_ICON = 'ok'

const NOT_ACTIVATED_COLOR = 'default'
const NOT_ACTIVATED_ICON = 'calendar'

class StatusHandler {
    getFullStatus = (status) => {
        switch (status) {
            case 'deactivated':
                return {
                    color: DEACTIVATED_COLOR,
                    icon: DEACTIVATED_ICON,
                    background_color: "gray",
                    message: "Deactivated",
                    explanation: "",
                    color_text_black : false
                }
            case 'notice':
            case 'todo':
                return {
                    color: NOTICE_COLOR,
                    icon: NOTICE_ICON,
                    color_icon: "#FFD700",
                    background_color: "notice",
                    message: "OK - With Notices",
                    explanation: "Check Alerts for details",
                    color_text_black : true
                }
            case 'warning':
            case 'canceled':
                return {
                    color: WARNING_COLOR,
                    icon: WARNING_ICON,
                    color_icon: "#f39c12",
                    background_color: "orange",
                    message: "Warning - Minor impairment",
                    explanation: "Check Alerts for details",
                    color_text_black : true
                }
            case 'error':
                return {
                    color: DANGER_COLOR,
                    icon: DANGER_ICON,
                    color_icon: "#dd4b39",
                    background_color: "red",
                    message: "Error - Major impairment",
                    explanation: "Check Alerts for details",
                    color_text_black : false
                }
            case 'maintenance':
            case 'in_progress':
                return {
                    color: MAINTENANCE_COLOR,
                    icon: MAINTENANCE_ICON,
                    background_color: "blue",
                    message: "Maintenance",
                    explanation: "Configured in Display ERP",
                    color_text_black : true
                }
            case 'not_activated':
                return {
                    color: NOT_ACTIVATED_COLOR,
                    icon: NOT_ACTIVATED_ICON,
                    background_color: "gray",
                    message: "Not Activated",
                    explanation: "",
                    color_text_black : false
                }
            case 'done':
            case 'ready':
            case 'ok':
                return {
                    color: SUCCESS_COLOR,
                    icon: SUCCESS_ICON,
                    background_color: "green",
                    message: "OK",
                    explanation: "Fully operational",
                    color_text_black : false
                }
            default:
                return {
                    color: NOT_ACTIVATED_COLOR,
                    icon: NOT_ACTIVATED_ICON,
                    background_color: "gray",
                    message: "Unknown status",
                    explanation: "",
                    color_text_black : false
                }
        }
    }

    getStatusWithoutMaintenance = (closedAt, alertStatusType) => {
        if (closedAt) {
            return {
                color: SUCCESS_COLOR,
                icon: SUCCESS_ICON
            }
        }

        return this.getStatusWithoutMaintenanceCheck(alertStatusType)
    }

    getStatusWithoutMaintenanceCheck = (alertStatusType) => {
        let status

        if(null === alertStatusType){
            return {
                color: SUCCESS_COLOR,
                icon: SUCCESS_ICON
            }
        }
        switch (alertStatusType) {
            case 'notice':
                status = {
                    color: NOTICE_COLOR,
                    icon: NOTICE_ICON
                }
                break
            case 'warning':
                status = {
                    color: WARNING_COLOR,
                    icon: WARNING_ICON
                }
                break
            case 'error':
                status = {
                    color: DANGER_COLOR,
                    icon: DANGER_ICON
                }
                break
            default:
                status = {
                    color: SUCCESS_COLOR,
                    icon: SUCCESS_ICON
                }
        }

        return status
    }
}

export default StatusHandler
