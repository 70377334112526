import {withRouter} from 'react-router-dom';
import AbstractByAircraft from './AbstractByAircraft';

class KnowledgeBaseDocumentByAircraft extends AbstractByAircraft {

    constructor(props) {
        super(props);
        this.specificUri = 'knowledge_base_documents';
        this.state = {
            resList: [],
            loading: true,
            currentPageNumber: 1,
            orderBy: 'ASC',
            currentFieldOrder: null,
            navigation: {
                totalItems: 0,
                first: '',
                next: '',
                last: '',
                totalPage: 0
            },
            error: {
                status: false,
                message: ''
            },
            fieldsName: [
                'Document Number',
                'Title',
                'Type',
                'Valid from',
                'Valid until'
            ]
        }
    }

    getProperty = (field) => {
        let property;

        switch (field) {
            case 'Document Number':
                property = 'documentNumber';
                break;
            case 'Title':
                property = 'title';
                break;
            case 'Type':
                property = 'knowledgeBaseDocumentType.name';
                break;
            case 'Valid from':
                property = 'validFrom';
                break;
            case 'Valid until':
                property = 'validUntil';
                break;
            default:
                property = '';
        }

        return property;
    }

    fieldClicked = (fields) => {
        if (this.props.clickable) {
            const uuid = fields[0];
            this.props.history.push(`/${this.props.icao}/knowledge_base_documents/${uuid}`);
        }
        return true;
    }

    getFields = () => {
        let fields = [];
        const resList = [...this.state.resList];

        resList.forEach(res => {
            fields.push([
                res.uuid,
                res.documentNumber ?? '',
                res.title ?? '',
                res.knowledgeBaseDocumentType.name,
                res.validFrom ?? '',
                res.validUntil ?? ''
            ])
        });

        return fields;
    };
}

export default withRouter(KnowledgeBaseDocumentByAircraft);
