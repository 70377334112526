import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {grantPermission} from "../../../../hoc/RoleService/RoleService";

class FlightStatusBox extends Component {
    render() {
        let departureAt = "";

        if (this.props.depatureAt) {
            departureAt = <div>
                <div style={{marginBottom: 10}}>
                    <b>
                        <i title="departure at" className="fa fa-calendar"
                        style={{fontSize: 17, color: '#3c8dbc'}}/> Departure At
                    </b>
                    <span className="pull-right">{this.props.depatureAt || '-'}</span>
                </div>
                {
                    grantPermission('flight', this.props.auth) ?
                    <Link to={'/' + this.props.icao + '/flights/' + this.props.uuid}>
                        <span className="btn btn-block btn-default"><i className="fa fa-eye" aria-hidden="true"/> Show Flight</span>
                    </Link>
                    :
                    <span></span>
                }
            </div>
        }

        let statusBox =
            <div className="col-md-5">
                <div className={"info-box bg-" + this.props.color}>
                    <span className="info-box-icon">
                        <i className={this.props.icon}/>
                    </span>
                    <div className="info-box-content">
                        <span className="info-box-text">{this.props.title}</span>
                        <span className="info-box-number">
                            {this.props.airport}
                        </span>
                        <div className="progress">
                            <div className="progress-bar" style={{width: '100%', background: "black"}}>
                            </div>
                        </div>
                        <div className="progress-description">
                            {this.props.descriptionLeft}
                            <span className="pull-right">
                                {this.props.descriptionRight}
                            </span>
                        </div>
                    </div>
                </div>
                {departureAt}
            </div>
        ;

        return (
            <div>
                {statusBox}
            </div>
        );
    }
}

export default FlightStatusBox;
