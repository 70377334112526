import React, {Component} from "react";
import { withRouter } from 'react-router-dom';
import AutoConfigByAircraftTable from "./AutoConfigByAircraftTable";
import {grantPermission} from "../../../hoc/RoleService/RoleService";


class AutoConfigByAircraft extends Component {

    render() {
        let configurationVersionRef = ''
        if (this.props.transportUnit.transportUnitConfiguration) {
            if (this.props.transportUnit.transportUnitConfiguration.configurationDefaultVersionRef) {
                configurationVersionRef = this.props.transportUnit.transportUnitConfiguration.configurationDefaultVersionRef + " (Set in fleet configuration)"
            }
        }

        if (this.props.transportUnit.configurationVersionRef) {
            configurationVersionRef = this.props.transportUnit.configurationVersionRef + " (Override for this aircraft)"
        }

        let buildConfiguration = ''
        if (this.props.transportUnit.transportUnitConfiguration && this.props.transportUnit.transportUnitConfiguration.containerBuildConfiguration) {
            buildConfiguration = this.props.transportUnit.transportUnitConfiguration.containerBuildConfiguration
        }

        let companyToken = ''
        if (this.props.transportUnit.configuredCompany && this.props.transportUnit.configuredCompany.tokenAutoConfiguration) {
            companyToken = this.props.transportUnit.configuredCompany.tokenAutoConfiguration
        }

        let pipelineVars = ''
        if (this.props.transportUnit.configurationPipelineVars) {
            pipelineVars = this.props.transportUnit.configurationPipelineVars
        }

        const autoConfigContent = <div>
            <p className="pad margin no-print">
                <div className="row">
                    <div className="col-md-6">
                        <table className="table table-bordered">
                            <tbody>
                            <tr>
                                <td>Self configuration activated</td>
                                <td>
                                    {this.props.transportUnit.autoConfigDeployer ?
                                        <span className='label label-success'>Yes</span>
                                        : <span className='label label-default'>No</span>}
                                </td>
                            </tr>
                            <tr>
                                <td>UGO builder reference</td>
                                <td>{configurationVersionRef}</td>
                            </tr>
                            <tr>
                                <td>Activation token</td>
                                <td>{companyToken}</td>
                            </tr>
                            <tr>
                                <td>Container Build Configuration</td>
                                <td>{buildConfiguration}</td>
                            </tr>
                            <tr>
                                <td>Pipeline vars</td>
                                <td>{pipelineVars}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-md-6">
                        <table className="table table-bordered">
                            <tbody>
                            <tr>
                                <td>Current applied reference</td>
                                <td>{this.props.transportUnit.lastAutoConfigRef}</td>
                            </tr>
                            <tr>
                                <td>Current hash</td>
                                <td>{this.props.transportUnit.lastAutoConfigHash}</td>
                            </tr>
                            <tr>
                                <td>Initial reference</td>
                                <td>{this.props.transportUnit.autoconfigurationReferenceInitial}</td>
                            </tr>
                            <tr>
                                <td>Initial hash</td>
                                <td>{this.props.transportUnit.autoconfigurationHashInitial}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {grantPermission('self_configuration', this.props.auth) ?
                    <p>Last self-config deployed: {this.props.transportUnit.lastAutoConfigDate} ({this.props.transportUnit.lastAutoConfigHash})</p>
                    : <></> }
            </p>
            {this.props.transportUnit.autoConfigDeployer ?
                <AutoConfigByAircraftTable
                    clickable={false}
                    transportUnitUuid={this.props.transportUnit.uuid}
                    search={this.props.search}
                    icao={this.props.icao}
                    auth={this.props.auth}
                    tableTitle="History"
                /> : ''}
        </div>

        return (
            <div>
                {autoConfigContent}
            </div>
        )
    }
}

export default withRouter(AutoConfigByAircraft);
