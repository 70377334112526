import React from 'react';
export default function MessageInfo(props) {
    return (
        <p>
            <div className="alert alert-danger alert-dismissible">
                <button onClick={props.refreshHandler} type="button" className="close"
                        aria-hidden="true"><i className='fa fa-refresh'/></button>
                <h4><i className="icon fa fa-ban"/> {props.message} </h4>
            </div>
        </p>
    )
}
