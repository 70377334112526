import React, { Component } from 'react'
import Modal from '../../UI/Modal/Modal'
import AuthService from '../../../hoc/AuthService/AuthService'
import Aux from '../../../hoc/Aux/Aux'
import Spinner from '../../UI/Spinner/Spinner'
import './ModalAck.css'
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import {formatDate, parseDate} from 'react-day-picker/moment';

class ModalAck extends Component {
    constructor(props) {
        super(props)
        this.auth = new AuthService()
        this.handleDayClick = this.handleDayClick.bind(this);
    }

    state = {
        alertDetail: '',
        ack: {
            comment: '',
            author: '',
            finishedAt: ''
        },
        user: {},
        loading: true,
        error: {
            status: false,
            message: ''
        },
        updateInProgress: false,
        selectedDay: undefined,
    };

    componentDidMount() {
        this.auth.fetch('transport_unit_alert_histories?id=' + this.props.alertId)
            .then(res => {
                if (res.code === 401) {
                    this.props.history.push({
                        pathname: '/login',
                        state: {previousUrl: this.props.location.pathname}
                    })
                }
                const alertDetail = res['hydra:member'][0]
                const ack = {...this.state.ack}
                ack.comment = alertDetail.comments
                ack.finishedAt = alertDetail.ackEndDatetime
                ack.author = alertDetail.ackUser

                this.setState(
                    {
                        ack,
                        alertDetail,
                        selectedDay: moment(ack.finishedAt).format('YYYY-MM-DD'),
                        loading: false,
                    }
                )
            })
            .catch(e => {
                this.setState({
                    loading: false,
                    error: {
                        status: true,
                        message: e.toString()
                    }})
            });
    }

    handleCommentChange = (e) => {
        const ack = {...this.state.ack};
        ack.comment = e.target.value;
        this.setState({ack})
    };

    handleDayClick(day, { selected, disabled }) {
        if (disabled) {
            return;
        }

        if (selected) {
            this.setState({ selectedDay: undefined });
            return;
        }

        const ack = {...this.state.ack};
        ack.finishedAt = day;
        this.setState({ selectedDay: day, ack });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({updateInProgress: true});

        const username = this.auth.parseJwt().preferred_username
        this.getUserAndSendAck(username)
    };

    sendAck = () => {
        const message = {
            method: 'PUT',
            body: JSON.stringify({
                ackEndDatetime: this.state.ack.finishedAt ? this.state.ack.finishedAt : null,
                comments: this.state.ack.comment ? this.state.ack.comment : null ,
                ackUser: this.state.user.id || this.state.user.id !== 'jj/mm/aaaa' ? '/api/users/'+this.state.user.id : null
            })
        }
        let closedAt = !this.state.alertDetail.closedAt ? false : true;
        const url = '?transportUnit.uuid=' + this.props.transportUnitUuid + '&exists[closedAt]=' + closedAt +   '&order[alertStatus]=DESC';

        this.auth.fetch('transport_unit_alert_histories/'+this.state.alertDetail.id, message)
            .then(res => {
                if (res.code === 401) {
                    this.props.history.push({
                        pathname: '/login',
                        state: {previousUrl: this.props.location.pathname}
                    })
                }
                this.props.closePopup()
                this.props.getContentPage(url)
                this.setState({updateInProgress: false})
            })
            .catch(e => {
                this.setState({
                    loading: false,
                    error: {
                        status: true,
                        message: e.toString()
                    }})
            })
    }

    getUserAndSendAck = (username) => {
        this.auth.fetch('users?username=' + username)
            .then(res => {
                if (res.code === 401) {
                    this.props.history.push({
                        pathname: '/login',
                        state: {previousUrl: this.props.location.pathname}
                    })
                }
                const user = res['hydra:member'][0]
                this.setState({user, loading: false})
                this.sendAck()
            })
            .catch(e => {
                this.setState({
                    loading: false,
                    error: {
                        status: true,
                        message: e.toString()
                    }})
            })
    }

    render () {
        const {color} = this.props;
        const {alertDetail} = this.state;
        const FORMAT = 'yyyy/MM/dd';

        let pageContent = (
            <Modal
                color={color}
                closeDetailHandler={this.props.closePopup}
                title='Chargement...'>
                <Spinner/>
            </Modal>
        )

        if (!this.state.loading) {
            let dateFinishedAtFormat = this.state.ack.finishedAt ? moment(this.state.ack.finishedAt).format('YYYY-MM-DD') : 'yyyy-mm-dd';
            pageContent = (
                <Modal
                    color={color}
                    closeDetailHandler={this.props.closePopup}
                    title={'Alert id: '+alertDetail.id}
                >
                    <p><strong>Created At: </strong> {alertDetail.createdAt ? alertDetail.createdAt : '-'}</p>
                    {alertDetail.alertReason && <p><strong>Description: </strong> {alertDetail.alertReason.description}</p>}
                    {alertDetail.alertReason && <p><strong>Type: </strong> {alertDetail.alertReason.alertStatus.alertStatusType}</p>}
                    <p><strong>Explanation: </strong> {alertDetail.explanation ? alertDetail.explanation : '-'}</p>
                    {alertDetail.ackUser && <p><strong>User: </strong> {alertDetail.ackUser.name}</p>}
                    <h3>Acknowledgement</h3>
                    <h5><em>Please acknowledge this alert if you want to hide it. The status of the Aircraft will ignore this one.</em></h5>
                    <p><strong>End of ACK: </strong> {alertDetail.ackEndDatetime ? alertDetail.ackEndDatetime : '-'}</p>
                    <p><strong>ACK comment: </strong> {alertDetail.comments ? alertDetail.comments : '-'}</p>
                    <p><strong>ACK author: </strong> {alertDetail.ackUser ? alertDetail.ackUser.name : '-'}</p>
                    <h3 style={{marginBottom: 15}}>Update Acknowledgement</h3>
                    <form onSubmit={this.handleSubmit}>
                        <div className="form-group has-feedback col-xs-12">
                            End of ACK <br/>
                            <DayPickerInput
                                className="form-control"
                                formatDate={formatDate}
                                parseDate={parseDate}
                                format={FORMAT}
                                value={dateFinishedAtFormat}
                                onDayChange={this.handleDayClick}
                            />
                        </div>
                        <div className="form-group col-xs-8">
                            Comment
                            <input
                                style={{ height: 100 }}
                                type='text'
                                className="form-control"
                                onChange={this.handleCommentChange}
                                value={this.state.ack.comment ? this.state.ack.comment : ''}
                                placeholder='Comment'
                            />
                        </div>
                        <div className="col-xs-4">
                            <br />
                            {this.state.updateInProgress
                                ? <button type="submit" className="btn btn-primary btn-block btn-flat disabled">
                                    <div style={{margin:'auto'}} className="loader text-center"> </div></button>
                                : <button type="submit" className="btn btn-primary btn-block btn-flat">Update</button>
                            }
                        </div>
                    </form>
                </Modal>
            )
        }

        return <Aux>{pageContent}</Aux>
    }
}

export default ModalAck
