import React from 'react';

function AircraftSummaryLegacy(lastFullMediaPartNumber, lastOtaMediaPartNumber, lastOtaLog) {
    return (
        <>
            <tr>
                <td>
                    <b>
                        <i title="Logs" className="fa fa-film" style={{fontSize: 17, color: '#3c8dbc'}}/> Current USB Medias
                        P/N
                    </b>
                </td>
                <td className="pull-right">{lastFullMediaPartNumber || '-'}</td>
            </tr>
            <tr>
                <td>
                    <b>
                        <i title="Logs" className="fa fa-cloud-download" style={{fontSize: 17, color: '#3c8dbc'}}/> Current
                        OTA Media P/N
                    </b>
                </td>
                <td className="pull-right">{lastOtaMediaPartNumber || '-'}</td>
            </tr>
            <tr>
                <td>
                    <b>
                        <i title="Logs" className="fa fa-cloud-download" style={{fontSize: 17, color: '#3c8dbc'}}/> Last
                        done OTA performed At
                    </b>
                </td>
                <td className="pull-right">{lastOtaLog || '-'}</td>
            </tr>
        </>
    );
}

export default AircraftSummaryLegacy;
