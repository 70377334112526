import React from "react";
import { withRouter } from 'react-router-dom';
import AbstractByAircraft from './AbstractByAircraft';
import AutoConfigStatus from "../../AutoConfig/AutoConfigStatus";

class AutoConfigByAircraftTable extends AbstractByAircraft {
    constructor(props) {
        super(props);
        this.specificUri = 'configuration_pipelines';
        this.state = {
            resList: [],
            loading: true,
            currentPageNumber: 1,
            orderBy: 'ASC',
            currentFieldOrder: null,
            navigation: {
                totalItems: 0,
                first: '',
                next: '',
                last: '',
                totalPage: 0
            },
            error: {
                status: false,
                message: ''
            },
            fieldsName: [
                'Reference (branch)',
                'Hash',
                'Started At',
                'Finished At',
                'Status',
                'Link to Gitlab',
                'Link to RMC session'
            ],
            tableTitle: this.props.tableTitle,
            messageTitle: this.props.messageTitle
        }
    }

    getProperty = (field) => {
        let property;

        switch (field) {
            case 'Reference (branch)':
                property = 'ref';
                break;
            case 'Hash':
                property = 'sha';
                break;
            case 'Started At':
                property = 'startedAt';
                break;
            case 'Finished At':
                property = 'finishedAt';
                break;
            case 'Status':
                property = 'status';
                break;
            case 'Link to Gitlab':
                property = 'webUrl';
                break;
            case 'Link to RMC session':
                property = 'remoteConnexion';
                break;
            default:
                property = '';
        }

        return property;
    }

    fieldClicked = (fields) => {
        if (this.props.clickable) {
            const AutoConfigUuid = fields[0];
            this.props.history.push(`/${this.props.icao}/todo/${AutoConfigUuid}`);
        }
        return true;
    }

    getFields = () => {
        let fields = [];
        const resList = [...this.state.resList];

        resList.forEach(res => {
            let linkToGitlab = res.webUrl ? <a className="btn btn-sm btn-default view_link" href={res.webUrl} target="_blank" rel="noopener noreferrer">Gitlab pipeline</a> : '';

            let linkToRMC = '';
            if (res.remoteConnexion && res.remoteConnexion.uuid) {
                linkToRMC = <a className="btn btn-sm btn-default view_link" href={`/${this.props.icao}/remote-connection/${res.remoteConnexion.uuid}`} rel="noopener noreferrer">RMC session</a>
            }

            let status = <AutoConfigStatus status={res.status} />;

            fields.push([
                res.uuid,
                res.ref,
                res.sha ? res.sha.substring(0, 8) : '',
                res.startedAt ? res.startedAt : '',
                res.finishedAt ? res.finishedAt : '',
                status,
                linkToGitlab,
                linkToRMC
            ])
        });

        return fields;
    };
}

export default withRouter(AutoConfigByAircraftTable);
