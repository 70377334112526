import React from 'react';

import './Th.css'

const th = (props) => (
    <th
        width={props.field === 'Status' ? 80 : null }
        onClick={() => props.tableHeadClicked(props.field)}
        className={props.classStyle}>{props.field}</th>
);

export default th;
