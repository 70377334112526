import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import ListRole from "../../utils/ListRole";
import CompanyService from "../../hoc/CompanyService/CompanyService";
import Auth from "../../hoc/AuthService/AuthService";
import '../../components/Login/Login.css';

class UrlChanger extends Component {
    constructor(props) {
        super(props)
        this.auth = new Auth()
        this.listRole = new ListRole()
        this.companyService = new CompanyService()
        this.state = {
            icao: '',
            companyList: [],
        }
    }

    componentDidMount() {
        document.body.classList.value = 'hold-transition login-page';
        this.getCompanies()
    }

    getCompanies() {
        this.auth.fetch('companies?pagination=false')
            .then(res => {
                if (res.code === 401) {
                    this.props.history.push('/login');
                }
                let reslist = res['hydra:member'] ? res['hydra:member'] : []
                this.logicUrl(reslist)
            })
            .catch(e => {
                this.setState({
                    loading: false,
                    error: {
                        status: true,
                        message: e.toString()
                    }
                })
            })
    }

    logicUrl = (companyList) => {
        let companies

        if (!this.state.icao) {
            companies = companyList.filter(company => company.isShowInDashboard === true)
            if (companies.length < 1) {
                window.location.replace('/403')
                return true
            }

            if (this.companyService.isDisplayUser(this.props.auth)) {
                window.location.replace(this.companyService.displayIcao.toLowerCase())
                return true
            }

            window.location.replace(companies[0].icao.toLowerCase())
            return true
        }
        return false
    }

    render() {
        return (
            <div className="login-box">
                <div>
                    <b>Redirecting...</b>
                </div>
            </div>
        )
    }
}

export default withRouter(UrlChanger);
